<template>
  <router-view />
</template>

<script>
import toolkitMixin from '@/mixins/toolkit';
import sessionMixin from '@/mixins/session';
export default {
  name: 'app',
  mixins: [toolkitMixin, sessionMixin],
  mounted() {
    // get session id
    this.getSessionId();
  }
};
</script>
