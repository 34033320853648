// 進行異步執行
import { apiTcmktGetUrl, apiGetSessionId } from '@/resource'
import { apiGetCurrentStepV2 } from '@/resource/register'

export default {
  async actionRegisterStep({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiGetCurrentStepV2()
        .then(result => {
          if (result.data.code === 0 && result.data.data) {
            commit('setRegisterStep', result.data.data)
            resolve(result.data.data)
          }
          reject(state.registerStep)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  async actionTradingToolUrl({ commit, state }, lang) {
    if (state.tradingToolUrl.length > 0) return
    return new Promise((resolve, reject) => {
      apiTcmktGetUrl().then(result => {
        commit('setTradingToolUrl', result.data.data)
        resolve(result.data.data)
      })
    })
  },
  async actionApiGetSessionId({ commit, state }) {
    let res = await apiGetSessionId()
    if (res.data.code === 0 && res.data.data) {
      commit('setSessionId', res.data.data)
    }
  },
}
