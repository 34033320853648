import { instance, instanceExternal } from './axios'
import qs from 'qs'

const baseUrl = '/api/'
const cpUrl = '/cp/api/'

const loginUrl = `${baseUrl}login/`
const fileUrl = `${baseUrl}file/`
const homeUrl = `${cpUrl}home/`
const withdrawalUrl = `${cpUrl}withdrawal/`
const paymentSelectionUrl = `${baseUrl}payment/`
const transferUrl = `${cpUrl}transfer/`
const depositUrl = `${cpUrl}deposit/`
const accountUrl = `${cpUrl}account/`
const cardRegisterUrl = `${cpUrl}card-register/`
const passwordUrl = `${cpUrl}password/`
const profileUrl = `${cpUrl}profile/`
const fundsUrl = `${cpUrl}funds/`
const transactionHistoryUrl = `${cpUrl}transactionHistory/`
const questionnaireUrl = `${cpUrl}questionnaire/`
const depositlResultPath = 'result'
const checkResetPasswordAuthorization = `${profileUrl}check_reset_password_authorization`
const submitResetProfilePassword = `${profileUrl}submit_reset_profile_password`
const submitResetMt4AccountPassword = `${cpUrl}password/submit_reset_mt4_password`
const identifyProofUrl = `${cpUrl}identityProof/`
const userProfileUrl = `${baseUrl}userProfile/`
const tcmktUrl = `${baseUrl}tcmkt/`
const demoUrl = `${cpUrl}demo/`
const googleTranslateBasic = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCCW3iNEXzwyqDJzqQosBQIDe_2amtzDCo`
const notificationUrl = `${baseUrl}notification/`
const sliderCheckerUrl = `${baseUrl}sliderChecker/`
const systemUrl = `${baseUrl}system/`
const tradingTips = `${baseUrl}tradingTips/`
const socialTradingUrl = `${cpUrl}socialTrading/`
const cardAuditUrl = `${cpUrl}card-audit/`
const mfaUrl = `${baseUrl}mfa/`

export const personalDetailUrl = `${baseUrl}personalDetail/`
export const verificationUrl = `${baseUrl}verification/`
export const verificationV2Url = `${baseUrl}verificationV2/`
export const questionUrl = `${baseUrl}question/`

/** login */
export const apiTo_login = data => instance.post(`${loginUrl}to_login`, qs.stringify(data))
export const apiTo_logout = data =>
  instance.get(`${loginUrl}to_logout`, { headers: { Pragma: 'no-cache' }, params: data })
export const apiTo_login_by_token = headers => instance.post(`${loginUrl}to_login_by_token`, '', headers)
export const apiTo_login_register = data => instance.post(`${loginUrl}to_login_register`, qs.stringify(data))

/** home */
export const apiQueryMetaTraderAccountDetails = data =>
  instance.post(`${homeUrl}query-metaTrader-account-details`, data)
export const apiQueryLeverageStatuses = data => instance.post(`${homeUrl}query-leverage-statuses`, data)
export const apiQueryAvailableLeverages = data => instance.post(`${homeUrl}query-available-leverages`, data)
export const apiUpdate_leverage = data => instance.post(`${homeUrl}update-leverage`, data, { timeout: 30000 })
export const apiUpdate_leverage_demo = data => instance.post(`${homeUrl}update-leverage-demo`, data, { timeout: 30000 })
export const apiReactivate_Account = data => instance.post(`${homeUrl}reactivate-account`, data)
export const apiHomeGet_demo_accs = data => instance.get(`${homeUrl}get-demo-accs`, data)
export const apiupdate_balance_demo = data => instance.post(`${homeUrl}update-balance-demo`, data)

/** userProfile*/
export const apiSetlanguage = data => instance.post(`${userProfileUrl}setlanguage`, qs.stringify(data))

/** withdrawal */
export const apiIsCreditCardDeposit_cp = data => instance.post(`${withdrawalUrl}isCreditCardDeposit_cp`, data)
export const apiGetWithdrawalData_cp = () => instance.post(`${withdrawalUrl}getWithdrawalData_cp`)
export const apiGetNonCreditCardWithdrawTypeCP = data =>
  instance.post(`${withdrawalUrl}getNonCreditCardWithdrawTypeCP`, data)
export const apiApplyWithdrawal_cp = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal_cp`, data, { headers: { token: token } })
export const apiRegisterUnionPayCard_cp = data => instance.post(`${withdrawalUrl}registerCard`, data)
export const apiCardInformation = data => instance.get(`${withdrawalUrl}queryUnionpayCard`, { param: data })
export const apiWithdrawalBlacklist = () => instance.get(`${withdrawalUrl}queryBlacklist`)
export const apiCreditCardBalances = data => instance.get(`${withdrawalUrl}creditCard/balances/${data.currency}`, data)
export const apiApplyWithdrawal_cp_batch = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal_cp_batch`, data, { headers: { token: token } })
export const apiCancelWithdraw = data =>
  instance.post(`${withdrawalUrl}cancelWithdrawalOrder?withdrawalId=${data.withdrawalId}`)
export const apiSendEmailVerificationCode = data => instance.post(`${mfaUrl}sendEmailVerificationCode`, data)

export const apiWithdrawalQueryRateUSDByTargetCurrency = targetCurrency =>
  instance.get(`${withdrawalUrl}queryRateUSDByTargetCurrency/${targetCurrency}`)

export const apiGetWithdrawalBankCode = () => instance.get(`${withdrawalUrl}queryBankCode`)
export const apiGetWithdrawalBankBranchCode = bankCode => instance.get(`${withdrawalUrl}queryAllByBankCode/${bankCode}`)
/** Payment */
export const apiPaymentSelection = data => instance.post(`${paymentSelectionUrl}info/getPaymentInfoList/`, data)

/** transfer */
export const apiGetTransferData_cp = () => instance.post(`${transferUrl}getTransferData_cp`)
export const apiApplyTransfer_cp = (data, token) =>
  instance.post(`${transferUrl}applyTransfer_cp`, qs.stringify(data), { headers: { token: token } })
export const apiTransferPermission = data => instance.post(`${transferUrl}transferPermission`, qs.stringify(data))

/** file */
export const apiDelete_file = data => instance.post(`${fileUrl}delete`, data)

/** account */
export const apiGetMetaTraderAccounts = () => instance.get(`${accountUrl}getMetaTraderAccounts`)
export const apiApplyAdditionalAccount = data =>
  instance.post(`${accountUrl}applyAdditionalAccount`, qs.stringify(data))
export const apiCheckEquity = data =>
  instance.get(`${accountUrl}check_equity?mt4Account=${data.mt4Account}&&amount=${data.amount}`)
export const apiCreateDemoAccount = data => instance.post(`${accountUrl}createDemoAccount`, data)
export const apiIsHideAccountType = () => instance.get(`${accountUrl}isHideAccountType`)
export const apiGetAdditionalAccountType = () => instance.get(`${accountUrl}getAdditionalAccountType`)
export const apiGetAccountType = () => instance.get(`${accountUrl}getAccountType`)

/** deposit */
export const apiDepositBlacklist = () => instance.get(`${depositUrl}queryBlacklist`)
export const apiRestrictedDeposit = () => instance.post(`${depositUrl}getRestrictedDepositData`)
export const apiRestrictedDepositOne = data => instance.post(`${depositUrl}getRestrictedDepositDataOne`, data)
export const apiFetch_card = data => instance.post(`${depositUrl}fetch_card`, data)
export const apiUpload_card = data => instance.post(`${depositUrl}upload_cc_img`, data)
export const apiQuery_mt_accounts = data => instance.post(`${depositUrl}query_mt_accounts`, qs.stringify(data))
export const apiQueryRate_USD_VND = () => instance.post(`${depositUrl}queryRate_USD_VND`)
export const apiRequire_token = () => instance.post(`${depositUrl}require_token`)
export const apiAnti_Reuse = () => instance.get(`${baseUrl}token/anti-reuse`)
export const apiQueryRate = () => instance.post(`${depositUrl}queryRate`)
export const apiQuery_paymentChannel = () => instance.post(`${depositUrl}query_paymentChannel`)
export const apiQueryPagsmileExchangeRate = () => instance.get(`${depositUrl}pagsmile/queryExchangeRate`)
export const apiQueryRate_USD_NGN = () => instance.post(`${depositUrl}queryRate_USD_NGN`)
export const apiQueryRate_USD_MYR = () => instance.post(`${depositUrl}queryRate_USD_MYR`)
export const apiQueryRate_USD_IDR = () => instance.post(`${depositUrl}queryRate_USD_IDR`)
export const apiQueryRate_USD_LAK = () => instance.post(`${depositUrl}queryRate_USD_LAK`)
export const apiQueryRate_USD_INR = () => instance.post(`${depositUrl}queryRate_USD_INR`)
export const apiQueryRate_USD_BRL = () => instance.post(`${depositUrl}queryRate_USD_BRL`)
export const apiQueryRate_USD_PHP = () => instance.post(`${depositUrl}queryRate_USD_PHP`)
export const apiQueryRate_USD_JPY = () => instance.post(`${depositUrl}queryRate_USD_JPY`)
export const apiQueryRateUSDByTargetCurrency = data =>
  instance.post(`${depositUrl}queryRateUSDByTargetCurrency`, qs.stringify(data))
export const apiQueryUserPayRigths = () => instance.post(`${depositUrl}queryUserPayRigths`)
export const apiGet_rate = data => instance.post(`${depositUrl}get_rate`, qs.stringify(data))
export const apiGet_holder_name = data => instance.post(`${depositUrl}get_holder_name`, qs.stringify(data))
export const apiGet_mobile_rate = data => instance.post(`${depositUrl}get_mobile_rate`, qs.stringify(data))

export const apiCc_solid = (data, headers) => instance.post(`${depositUrl}cc_payment_sd`, data, { headers: headers })
export const apiInternational_bank_payment = (data, token) =>
  instance.post(`${depositUrl}international_bank_payment`, data, { headers: { token: token } })
export const apiwisep2p_payment = (data, token) =>
  instance.post(`${depositUrl}wise/p2p/payment`, data, { headers: { token: token } })
export const apiCrypto_payment = (data, token) =>
  instance.post(`${depositUrl}crypto_payment`, data, { headers: { token: token } })
export const apiCryptousdt_payment = (data, token) =>
  instance.post(`${depositUrl}cryptousdt_payment`, data, { headers: { token: token } })
export const apiCryptouBtcCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_btc`, data, { headers: { token: token } })
export const apiBanxaCc_payment = (data, token) =>
  instance.post(`${depositUrl}banxa-cc`, data, { headers: { token: token } })
export const apiCryptouOmniCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_omni`, data, { headers: { token: token } })
export const apiCryptousdtcps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_usdt`, data, { headers: { token: token } })
export const apiCryptoTrcCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_trc`, data, { headers: { token: token } })

/** cardRegister */
export const apiCredit_card = data => instance.post(`${cardRegisterUrl}credit_card`, data)
export const apiHistoryList = data => instance.post(`${cardRegisterUrl}history/list`, data)
export const apiGetCardDetials = data =>
  instance.get(`${cardRegisterUrl}get-card-info?cardId=${data.cardId}&&cardType=${data.cardType}`)
export const apiRemoveCard = data => instance.post(`${cardRegisterUrl}unionpay_card/update`, data)
export const apiUpdateCreditCard = data => instance.post(`${cardRegisterUrl}/credit_card/update`, data)

/**card audit */
export const cardAuditSubmit = data => instance.post(`${cardAuditUrl}submit`, data)

/** password */
export const apiUpdate_mt4_account_password = data => instance.post(`${passwordUrl}update_mt4_account_password`, data)
export const apiReq_reset_mt4_password = data => instance.post(`${passwordUrl}req_reset_mt4_password`, data)
export const apiCheck_new_password = data => instance.post(`${passwordUrl}check_new_password`, data)
export const apiCheck_current_password = data => instance.post(`${passwordUrl}check_current_password`, data)

/** profile */
export const apiInfo = () => instance.post(`${profileUrl}info`)
export const apiCheckPassword = data => instance.post(`${profileUrl}checkPassword`, qs.stringify(data))
export const apiEncryptPublicKey = (token = null) => instance.get(`${cpUrl}keyPair/pk?token=${token}`)
export const apiUpdatePassword = (data, publicKey) =>
  instance.post(`${profileUrl}updatePassword`, qs.stringify(data), { headers: { pk: publicKey } })
export const apiReq_reset_profile_password = data =>
  instance.post(`${profileUrl}req_reset_profile_password`, qs.stringify(data))
export const apiCheckMfaSwitchd = () => instance.get(`${profileUrl}checkMfaSwitch`)
export const apiForceUpdatePassword = data => instance.post(`${profileUrl}force_update_password`, data)

/** funds */
export const apiTransferHistoryGet = data =>
  instance.get(`${fundsUrl}transfer-history/get`, {
    params: data,
  })

/** identityProof */
export const apiVerificationIdentityProofs = data => instance.post(`${identifyProofUrl}verification`, data)
export const apiCheckRecordExists = data => instance.post(`${identifyProofUrl}check_record_exists`, data)
export const apiGet_last_identifiy_proof = id =>
  instance.get(`${identifyProofUrl}get_last_identifiy_proof?documentType=${id}`)
export const apiAttach_new_document = data => instance.post(`${identifyProofUrl}attach_new_document`, data)

/**
 *Thirdparty Deposit Result
 * @param {*} url
 * @param {*} data
 */
export const apiThirdPartyDepositResult = (paymentType, data) =>
  instance.post(`${depositUrl}` + paymentType + `/${depositlResultPath}`, data, {
    timeout: 30000,
  })

/** transactionHistory */
export const apiTransactionHistory = (url, data) => instance.post(`${transactionHistoryUrl}${url}`, qs.stringify(data))
export const apiDownloadAccountTransactionHistoryPDF = data =>
  instance.post(`${transactionHistoryUrl}/downloadAccountTransactionHistoryPDF`, data)

/** questionnaireUrl */
export const apiCheck = data => instance.post(`${questionnaireUrl}check`, data)

/**
 * reset password
 */
export const apiCheckResetPasswordAuthorization = data => instance.post(`${checkResetPasswordAuthorization}`, data)
export const apiSubmitResetProfilePassword = (data, publicKey) =>
  instance.post(`${submitResetProfilePassword}`, qs.stringify(data), { headers: { pk: publicKey } })
export const apiSubmitResetMt4AccountPassword = data => instance.post(`${submitResetMt4AccountPassword}`, data)

/** tcmktUrl */
export const apiTcmktCheckDeposit = () => instance.post(`${tradingTips}check/deposit`)
export const apiTcmktCheckTnc = () => instance.post(`${tcmktUrl}check/tnc`)
export const apiTcmktTncAccept = () => instance.post(`${tcmktUrl}tnc/accept`)
export const apiTcmktGetUrl = () => instance.post(`${tcmktUrl}getUrl`)

/** tcvideoUrl */
export const apiProCheckUnlocked = () => instance.get(`${tradingTips}/unlocked`)

/** demoUrl */
export const apiGet_demo_accs = () => instance.get(`${demoUrl}get_demo_accs`)

/** google translation */
export const apiGetTranslation = data =>
  instanceExternal.get(`${googleTranslateBasic}`, {
    params: data,
  })

/** notification */
export const apiGetNotifications = ({ country }) => instance.get(`${notificationUrl}enabled?country=${country}`)

/** Verification */
export const apiSecurityPassed = data =>
  instance.get(`${sliderCheckerUrl}passed`, {
    params: data,
  })

/** systemUrl */
export const apiInformation = () => instance.get(`${systemUrl}information`)

/**promotion */
const campaignUrl = `${baseUrl}campaign/`
export const apiReferralExchangeRate = data => instance.get(`${baseUrl}exchange-rate?from=${data.from}&to=${data.to}`)
export const apiReferralList = () => instance.get(`${campaignUrl}refer-a-friend/v2/participants`)
export const apiReferralEligibility = () => instance.get(`${campaignUrl}refer-a-friend/v2/eligible`)
export const apiReferralInfo = () => instance.get(`${campaignUrl}refer-a-friend/v2/info`)
export const apiReferralSubmitRedeem = data => instance.post(`${campaignUrl}refer-a-friend/v2/redeem`, data)

export const apiCampaignInfo = id => instance.get(`${campaignUrl}participants?campaignId=${id}`)
export const apiEligibleCampaigns = () => instance.get(`${campaignUrl}eligible-campaigns`)
export const apiParticipatedCampaigns = () => instance.get(`${campaignUrl}participated-campaigns`)
export const apiTncInfo = id => instance.get(`${campaignUrl}info?campaignId=${id}`)
export const apiCampaignOpt = (id, optIn) => instance.post(`${campaignUrl}opt-in?campaignId=${id}&optIn=${optIn}`)

export const apiRedeemableAcc = id => instance.get(`${campaignUrl}redeemable-accounts?campaignId=${id}`)
export const apiSubmitRedeem = data => instance.post(`${campaignUrl}redeem`, data)
export const apiReferralLink = id => instance.get(`${campaignUrl}campaign-code?campaignId=${id}`)

/** Social Trading */
export const apiCheckSocialTradingFollowerResult = data =>
  instance.post(`${socialTradingUrl}checkSocialTradingFollowerResult`, qs.stringify(data))

/**session */
export const apiGetSessionId = () => instance.get(`${baseUrl}tool/session-id`)
