import { apiEligibleCampaigns, apiParticipatedCampaigns } from '@/resource'

export default {
  async actionGetEligibleCampaigns({ commit, state }) {
    const getEligibleCampaigns = apiEligibleCampaigns().then(e => Promise.resolve(e))
    const getParticipatedCampaigns = apiParticipatedCampaigns().then(e => Promise.resolve(e))

    Promise.all([getEligibleCampaigns, getParticipatedCampaigns]).then(result => {
      if (result[0].data.code === 0) commit('setEligibleCampaigns', result[0].data.data)
      if (result[1].data.code === 0) commit('setParticipatedCampaigns', result[1].data.data)
    })
  },
}
