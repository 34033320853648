export default {
  countries: [],
  allCountries: [],
  provinces: [],
  nationalities: [],
  birthPlace: [],
  phoneCodes: [],
  EFQuestion: [],
  tradingQuestions: [],
  disableField: false,
}
