export default {
  setRestrictedDeposit(state, value) {
    state.restrictedDeposit = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = ''
    })
  },
}
