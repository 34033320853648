import Vue from 'vue'
import Router from 'vue-router'
import identity from './identity'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          path: '/',
          name: '/',
          meta: {
            noShowLoading: true,
          },
        },
        {
          path: '/logout',
          name: 'logout',
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
            restrictBanner: true,
          },
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/register/Register'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/demo',
          name: 'demo',
          component: () => import('@/views/register/Demo'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/authority',
          name: 'authority',
          component: () => import('@/views/register/Authority'),
        },
        {
          path: '/registerFinish',
          name: 'registerFinish',
          component: () => import('@/views/register/RegisterFinish'),
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/homeDemo',
          name: 'homeDemo',
          component: () => import('@/views/HomeDemo'),
        },
        {
          path: '/liveAccount',
          name: 'liveAccount',
          component: () => import('@/views/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openLiveAccount',
          name: 'openLiveAccount',
          component: () => import('@/views/OpenLiveAccount'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openDemoAccount',
          name: 'openDemoAccount',
          component: () => import('@/views/OpenDemoAccount'),
        },
        {
          path: '/depositFunds',
          name: 'depositFunds',
          component: () => import(`@/views/deposit/DepositFunds`),
          meta: {
            authority: true,
          },
        },
        {
          path: '/restrictDeposit',
          name: 'restrictDeposit',
          component: () => import(`@/views/deposit/restrictDeposit`),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/withdrawFunds',
          name: 'withdrawFunds',
          component: () => import('@/views/withdraw/WithdrawFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transferFunds',
          name: 'transferFunds',
          component: () => import('@/views/TransferFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transactionHistory',
          name: 'transactionHistory',
          component: () => import('@/views/TransactionHistory'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/downloads',
          name: 'downloads',
          component: () => import('@/views/Downloads'),
        },
        {
          path: '/myProfile',
          name: 'myProfile',
          component: () => import('@/views/MyProfile'),
        },
        {
          path: '/changePassword',
          name: 'changePassword',
          component: () => import('@/views/ChangePassword'),
        },
        {
          path: '/contactUs',
          name: 'contactUs',
          component: () => import('@/views/ContactUs'),
        },

        // pages of deposit
        {
          path: '/deposit/creditOrDebit',
          name: 'creditOrDebit',
          component: () => import('@/views/deposit/CreditOrDebit'),
        },
        {
          path: '/deposit/internationalSwift',
          name: 'internationalSwift',
          component: () => import('@/views/deposit/InternationalSwift'),
        },
        {
          path: '/deposit/Crypto',
          name: 'Crypto',
          component: () => import('@/views/deposit/Crypto'),
        },
        {
          path: '/deposit/wiseP2p',
          name: 'WiseP2p',
          component: () => import('@/views/deposit/wiseP2p'),
        },
        {
          path: '/deposit/banxaCC',
          name: 'BanxaCC',
          component: () => import('@/views/deposit/banxaCC'),
        },
        // pages of deposit result
        {
          path: '/thirdPartyDepositResult/:id',
          name: 'thirdPartyResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositFailed/:id',
          name: 'thirdPartyFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/sd/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/shared/LoginLayout'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/LoginClient'),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/login/to_login',
          name: 'to_login',
          component: () => import('@/views/LoginClient'),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/forgetPassword',
          name: 'forgetPassword',
          component: () => import('@/views/ForgetPasswordReq'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetProfilePassword',
          name: 'resetProfilePassword',
          component: () => import('@/views/ResetProfilePassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/ResetPassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/forceChangePassword',
          name: 'forceChangePassword',
          component: () => import('@/views/ForceChangePassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/error',
          name: 'error',
          component: () => import('@/views/register/Error'),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
      ],
    },
    {
      path: '',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})
export default router
identity.authorize()
