// 進行異步執行
import { apiRestrictedDeposit, apiRestrictedDepositOne } from '@/resource'

export default {
  async actionRestrictDeposit({ commit, state }) {
    if (state.restrictedDeposit.length > 0) return state.restrictedDeposit
    return new Promise((resolve, reject) => {
      apiRestrictedDepositOne({ paymentType: 2, paymentChannel: 1 }).then(result => {
        let data = result.data.length <= 0 ? 0 : result.data
        commit('setRestrictedDeposit', data)
        resolve(data)
      })
    })
  },
  // async actionRestrictDeposit({ commit, state }) {
  //   if (state.restrictedDeposit.length > 0) return state.restrictedDeposit
  //   return new Promise((resolve, reject) => {
  //     apiRestrictedDeposit().then(result => {
  //       let data = result.data.length <= 0 ? 0 : result.data
  //       commit('setRestrictedDeposit', data)
  //       resolve(data)
  //     })
  //   })
  // },
}
