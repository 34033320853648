export default {
  en: 'English',
  zh: '中文',
  fr: 'Francês',
  common: {
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    country: { international: 'Internacional' },
    verification: { toSlide: 'Deslize para completar o quebra-cabeça' },
    field: {
      nameOnCard: 'Nome no cartão',
      first4Digits: 'Primeiros 4 Dígitos no Cartão',
      last3Digits: 'Últimos 3 Dígitos no Cartão',
      first6Digits: 'Primeiros 6 Dígitos no Cartão',
      last4Digits: 'Últimos 4 Dígitos no Cartão',
      bankName: 'Nome do Banco',
      bankCode: 'Código do Banco',
      bankBranchCode: 'Código de sucursal',
      bankAddress: 'Endereço do Banco',
      branchAddress: 'Endereço da Filial',
      bankBranch: 'Agência Bancária',
      bankCity: 'Cidade do Banco',
      bankProvince: 'Província do Banco',
      bankBeneficiaryName: 'Nome do Beneficiário Bancário',
      bankBeneAccName: 'Nome da Conta do Beneficiário Bancário',
      bankBeneAccNum: 'Número de Conta do Beneficiário Bancário',
      bankBeneAddress: 'Endereço do Beneficiário',
      bankAccNum: 'Número de Conta Bancária',
      bankAccName: 'Nome da Conta Bancária',
      bankHolderAddress: 'Endereço do Titular da Conta',
      address: 'Endereço',
      accName: 'Nome da Conta',
      accNum: 'Número de conta',
      accNumTo: 'Número de Conta',
      bsbNum: 'Número BSB',
      swiftNum: 'Número SWIFT',
      swift: 'Código SWIFT',
      swiftNonAUD: 'Código SWIFT (Contas Não AUD)',
      ifscCode: 'IFSC code',
      amt: 'Montante',
      amtUSD: 'Valor (USD)',
      imptNotes: 'Notas importantes',
      yes: 'Sim',
      no: 'Não',
      payMethod: 'Método de Pagamento:',
      expDate: 'Data de Validade',
      bsb: 'BSB',
      abaSortCode: 'Código ABA/Sort:',
      country_region: 'País / Região',
      currLeverage: 'Alavancagem Atual',
      accEquity: 'Capital da conta',
      newLeverage: 'Alavancagem Desejada',
      currPw: 'Palavra-passe Atual',
      newPw: 'Nova Palavra-passe',
      confirmPw: 'Confirmar a Nova Palavra-passe',
      loginEmail: 'E-mail para Início de Sessão',
      email: 'E-mail',
      pw: 'Palavra-passe',
      Account: 'Account-pt',
      accType: 'Tipo de Conta',
      name: 'Nome',
      emailAdd: 'Endereço de E-mail',
      phone: 'Número de Telefone',
      dob: 'Data de Nascimento',
      nat: 'Nacionalidade',
      country: 'País de residência',
      street: 'Número da Rua/Nome da Rua',
      suburbOrCity: 'Freguesia/Cidade',
      provinceOrState: 'Concelho/Distrito',
      postcode: 'Código Postal',
      empStat: 'Situação Profissional',
      occupation: 'Profissão',
      annIncome: 'Rendimento Anual',
      save: 'Poupança/Investimento',
      source: 'Fonte de Rendimento',
      industry: 'Atividade',
      statusDef: 'Status definitions',
      month: 'Mês',
      year: 'Ano',
      select: 'Selecionar',
      completed: 'Concluído',
      incomplete: 'Incompleto',
      submitted: 'Submetido',
      successful: 'Com Sucesso',
      processing: 'Em Processamento',
      cancelled: 'Cancelado',
      failed: 'Falhado',
      rejected: 'Rejeitado',
      reversedfailed: 'Falha Revertida',
      upload: 'Carregar',
      ccNum: 'Número do Cartão de Crédito',
      bankCardNumber: 'Número do Cartão Bancário',
      cardHolderName: 'Nome do Titular do Cartão',
      reservedMobileNumber: 'Número de Telemóvel para o Banco',
      nationalId: 'Cartão Nacional de Identidade',
      cardPhoto: 'Foto do Cartão (frente)',
      notes: 'Notas',
      bankAccOrIBAN: 'Número da Conta Bancária/IBAN',
      selectWithdrawCard: 'Selecione o Seu Cartão para Levantamentos',
      updateCardPhoto:
        'Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
      wallet: 'Carteira',
      resetBalTo: 'Redefinir o Saldo para',
      confirmation: 'Tem a certeza?',
      removeNote: 'Quer mesmo apagar este registo? Este processo não pode ser desfeito.',
      depositCurrency: ' Moeda de depósito',
      bankAccountCurrency: 'Moeda da Conta Bancária',
      enterVerificationCode: 'Insira o Código de Verificação',
      beneficiaryName: 'Nome do beneficiário',
    },
    column: {
      date: 'DATA',
      type: 'TIPO',
      server: 'SERVIDOR',
      status: 'ESTADO',
      comment: 'COMENTÁRIO',
      accNum: 'NÚMERO DE CONTA',
      currency: 'MOEDA',
      equity: 'CAPITAL PRÓPRIO',
      credits: 'Créditos',
      balance:"Equilíbrio",
      leverage: 'ALAVANCAGEM',
      resetMT4PW: 'REDEFINIR A PALAVRA PASSE DO MT4',
      resetMT5PW: 'REDEFINIR A PALAVRA PASSE DO MT5',
      tradingAcc: 'CONTA DE NEGOCIAÇÃO',
      method: 'MÉTODO',
      amt: 'VALOR',
      procNote: 'NOTAS DO PROCESSO',
      number: 'NÚMERO DO CARTÃO',
      resetBal: 'REDEFINIR O SALDO',
      remove: 'Excluir',
    },
    key: { from: 'DE', to: 'PARA' },
    fund: {
      must: 'OBRIGAÇÃO',
      infoHover: 'O nome no seu cartão {deve} corresponder ao nome da sua conta de negociação',
      ccUploadReminder:
        'Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
    },
    button: {
      bkToHm: 'VOLTAR AO INÍCIO',
      smsLogin: 'INÍCIO DE SESSÃO NO TELEMÓVEL',
      userLogin: 'EMAIL LOGIN',
      goToIB: 'IR Para o Portal IB',
      logout: 'ENCERRAR SESSÃO',
      submit: 'SUBMETER',
      selectFile: 'Selecionar Ficheiro',
      depositFunds: 'DEPOSITAR FUNDOS',
      withdrawFunds: 'LEVANTAR FUNDOS',
      ibPortal: 'PORTAL IB',
      download: 'DESCARREGAMENTOS',
      changeLeverage: 'ALTERAR ALAVANCAGEM',
      cancel: 'CANCELAR',
      changePw: 'ALTERAR PALAVRA-PASSE',
      forgetPw: 'ESQUECEU A PALAVRA-PASSE',
      confirm: 'CONFIRMAR',
      login: 'INÍCIO DE SESSÃO',
      forgotPw: 'Esqueceu a Palavra-passe?',
      completeQuestionnaire: 'QUESTIONÁRIO COMPLETO',
      identityProof: 'INSCRIÇÃO COMPLETA',
      upload: 'CARREGAR',
      hide: 'OCULTAR',
      showAll: 'MOSTRAR TUDO',
      export: 'EXPORTAR',
      ok: 'OK',
      done: 'CONCLUÍDO',
      continue: 'Continuar',
      sendCode: 'Enviar Código',
      resendCode: 'Reenviar código',
    },
    formValidation: {
      accReq: 'O número da conta é obrigatório',
      amtReq: 'O valor é obrigatório',
      ccReq: 'O número do cartão de crédito é obrigatório',
      cardReq: 'O número do cartão é obrigatório',
      cardOnNameReq: 'O nome no cartão é obrigatório',
      digits16: 'Por favor, insira 16 dígitos',
      digits3or4: 'Por favor, insira 3 ou 4 dígitos',
      digitsFirst6: 'Por favor, insira os primeiros 6 dígitos',
      digitsLast4: 'Por favor, insira os últimos 4 dígitos',
      digitsLast3: 'Por favor, insira os últimos 3 dígitos',
      mid6Digits: 'Por favor, insira os {number} dígitos do meio',
      expDateReq: 'A data de validade é obrigatória',
      cvvReq: 'O Código de Segurança CVV é obrigatório',
      file: 'Por favor, faça o carregamento de um ficheiro',
      filePhoto: 'A Foto do Cartão de Crédito é obrigatória',
      fileReceipt: 'Por favor, faça o carregamento de um recibo',
      filePhotoReq: 'A Foto do Cartão é obrigatória',
      amtLarger: 'O valor não pode ser nulo e deve ser maior ou igual a ${minLimit}',
      amt0: 'O valor não pode ser 0',
      amtLess: 'O valor do seu depósito não pode ser superior a ${maxLimit} {currency}',
      ifscCodeReq: 'IFSC Code is required',
      note256: 'O limite do comprimento da nota é de 256 caracteres',
      noteMoreThan: 'O limite do comprimento da nota é de {limit} caracteres',
      noteLessThan: 'O limite do comprimento da nota é de {limit} caracteres',
      emailFormat: 'Por favor, insira o endereço de e-mail correto',
      payMethodReq: 'O método de pagamento é obrigatório',
      expReq: 'A data de validade é obrigatória',
      bankNameReq: 'O Nome do Banco é obrigatório',
      bankCityReq: 'A Cidade do Banco é obrigatória',
      bankProvinceReq: 'O Concelho do Banco é obrigatório',
      bankAddressReq: 'O Endereço do Banco é obrigatório',
      bankBranchReq: 'A Agência Bancária é obrigatória',
      bankAccNumReq: 'O Número da Conta Bancária é obrigatório',
      bankBeneficiaryNameReq: 'O Nome do Beneficiário Bancário é obrigatório',
      bankHolderAddressReq: 'O Endereço do Titular da Conta é obrigatório',
      swiftReq: 'O código Swift é obrigatório',
      accHolderAddress: 'O Endereço do Titular da Conta é obrigatório',
      bsbReq: 'O BSB é obrigatório',
      accNumIBANReq: 'O Número da Conta Bancária/IBAN é obrigatório',
      accNameReq: 'O Nome da Conta Bancária é obrigatório',
      withdrawMethodReq: 'Por favor, selecione um método de levantamento',
      tnc: 'Por favor, concorde com os Termos e Condições.',
      currPwReq: 'A palavra-passe atual é obrigatória',
      currPwWrong: 'A palavra-passe atual está incorreta',
      newPwReq: 'A nova palavra-passe é obrigatória',
      newPwFormat:
        'A sua palavra-chave deve ter entre 8-16 caracteres e uma mistura de letras (a-z e A-Z), números (0-9) e caracteres especiais como !@#$%^&*.()',
      newPwAccFormat:
        'A sua palavra-chave deve ter entre 8-16 caracteres e uma mistura de letras (a-z e A-Z), números (0-9) e caracteres especiais como !@#$%^&*',
      IdenficalPw: 'A nova palavra-passe não pode ser igual à antiga',
      confirmPwReq: 'Por favor, insira a nova palavra-passe novamente',
      confirmNotMatch: 'Os dois dados inseridos não coincidem',
      pwReq: 'A palavra-passe é obrigatória',
      accCurrReq: 'Selecione uma moeda de base para sua conta',
      tradAccReq: 'Por favor, selecione uma conta de negociação',
      answerReq: 'Por favor, selecione',
      bankCardReq: 'Por favor, selecione um cartão',
      phoneNumberReq: 'O Número de Telemóvel para o Banco é obrigatório',
      nationaIdReq: 'A identificação Nacional é obrigatória',
      numberReq: 'Por favor, insira o número',
      alphanumericReq: 'Por favor, insira o alfanumérico',
      idTypeReq: 'Por favor, escolha um Tipo de Identificação',
      dobReq: 'Por favor, escolha a sua data de nascimento',
      pobReq: 'Por favor, escolha o seu local de nascimento',
      nationalityReq: 'Por favor, escolha a sua nacionalidade',
      titleReq: 'Por favor, escolha o seu título',
      firstNameReq: 'Por favor, insira o seu nome próprio',
      lastNameReq: 'Por favor, insira o seu sobrenome',
      emailReq: 'Por favor, insira o seu endereço de e-mail',
      addressReq: 'Por favor, insira a sua morada',
      suburbReq: 'Por favor, insira a sua cidade ou freguesia',
      stateReq: 'O distrito ou concelho é obrigatório',
      postcodeReq: 'Por favor, insira o seu código postal',
      countryReq: 'Por favor, selecione o seu país de residência',
      visaReq: 'Por favor, utilize Visa para depositar',
      masterCardReq: 'Por favor, utilize Master Card para depositar',
      masterCardVisaReq: 'Por favor, utilize Master Card/Visa para depositar',
      bankCodeReq: 'O Código bancário é obrigatório',
      bankBranchCodeReq: 'O Código da sucursal é obrigatório',
      dynamicReq: '{dynamic} é obrigatório',
      mobReq: 'o telemóvel é obrigatório',
      resetBalAmt: 'Por favor, defina o montante entre 0 e 1.000.000',
      verCodeReq: 'O código de verificação é obrigatório',
      noEligibleAccounts: 'Não existem contas elegíveis',
      beneficiaryNameReq: 'O nome do beneficiário do banco é obrigatório',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Bank transfer/BPay/PoliPay',
      banktransfer: 'Transferência Bancária',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferência UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Cartão de Crédito',
      update: 'Atualização',
      archive: 'Arquivo',
      updateExpiryMsg:
        'Atualize a data de expiração do seu cartão para utilizar o seu cartão para levantamentos ',
      requiredDocument: 'Documentos necessários para o arquivamento de cartões',
      officialLetter: 'Carta/email oficial do banco declarando que o cartão já não está disponível',
      bankStatement: 'Extrato bancário apresentando uma transação feita com este cartão',
      expiredCard: 'Cartões expirados/não disponíveis',
      confirmArchive: 'Tem a certeza de que quer arquivar este cartão?',
      confirmUpdate:
        'Tem a certeza de que quer atualizar a data de validade deste cartão? Nota: A VT Markets não será responsável se o levantamento falhar devido à data de expiração incorreta fornecida',
      pendingArchiveRequest:
        'O seu pedido de arquivo de cartão de crédito está atualmente pendente de aprovação, por favor, aguarde até que o processo esteja concluído antes de fazer um levantamento, ou contacte o nosso serviço de apoio ao cliente através de chat ao vivo para agilizar o processo.',
      bpay: 'Bpay',
      creditdebitcard: 'Cartão de Crédito/Débito',
      bankwiretransferinternational: 'Transferência Bancária (Internacional)',
      banktransferaustralia: 'Transferência Bancária (Austrália)',
      banktransferinternational: 'Transferência Bancária (Internacional)',
      banktransferchina: 'Transferência Bancária (Internacional)',
      mobilepay: 'Mobile pay',
      brokertobrokertransfer: 'Transferência de Corretora para Corretora',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Serviço Bancário Online (Tailândia)',
      internetbankingnigeria: 'Serviço Bancário Online (Nigéria)',
      internetbankingvietnam: 'Serviço Bancário Online (Vietname)',
      internetbankingmalaysia: 'Serviço Bancário Online (Malásia)',
      internetbankingindonesia: 'Serviço Bancário Online (Indonésia)',
      internetbankingindia: 'Serviço Bancário Online (India)',
      internetbankinguk: 'Serviço Bancário Online (Reino Unido)',
      internetbankinglaos: 'Serviço Bancário Online (Laos)',
      internetbankinghongkong: 'Serviço Bancário Online (Hong Kong)',
      pagsmile: 'Serviço Bancário Online (Brazil)',
      internetbankingphilippine: 'Serviço Bancário Online (Philippines)',
      internetbankingeu: 'Banco pela Internet (UE)',
      internetbankingmexico: 'Serviço Bancário Online (México)',
      internetbankingcolumbia: 'Serviço Bancário Online (Colômbia)',
      internetbankingchile: 'Serviço Bancário Online (Chile)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transferência Bancária do Vietnam',
      nigeriabanktransfer: 'Transferência Bancária da Nigéria',
      polipayment: 'Pagamento com Poli',
      bankwiretransferaustralia: 'Transferência Bancária (Austrália)',
      thailandinstantbankwiretransfer: 'Transferência Bancária Instantânea Tailandesa',
      nigeriainstantbankwiretransfer: 'Transferência Bancária Instantânea Nigeriana',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea Vietnamita',
      laosinstantbankwiretransfer: 'Transferência bancária instantânea de Laos',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea Malásia',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea Philippines',
      mexicobanktransfer: 'Transferência Bancária Instantânea México',
      colombiabanktransfer: 'Transferência Bancária Instantânea Colômbia',
      chilebanktransfer: 'Transferência Bancária Instantânea Chile',
      brazilbanktransfer: 'Transferência Bancária Instantânea Brasil',
      banktransferbpaypolipayaustralia: 'Transferência Bancária/BPay/PoliPay (Austrália)',
      banktransferbpaypolipayinternational: 'Transferência Bancária/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Canadian Local Bank Transfer',
      hongkongbanktransfer: 'Transferência Bancária Local de Hong Kong',
      cryptobitcoin: 'Criptomoeda-Bitcoin',
      cryptotetheromni: 'Criptomoeda-Tether(OMNI)',
      cryptotethererc20: 'Criptomoeda-Tether(ERC20)',
      cryptotethertrc20: 'Criptomoeda-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptomoeda-Bitcoin',
      cryptocurrencyusdt: 'Criptomoeda-USDT',
      dasshpewallet: 'Carteiras da Índia',
      available: 'Disponível em',
      pending: 'Pendente',
      unavailable: 'Não Disponível',
      fullyRefunded: 'Totalmente Reembolsado',
      archiveThisCard: 'Por favor arquive este cartão se já não for válido ou não estiver em uso',
      manageCreditCards: 'Gerir cartões de crédito',
    },
    overview: 'Visão geral',
    referrals: 'Recomendações',
    pendingAction: 'Ação Pendente',
    notApplicable: 'Não aplicável',
    overLimit: 'Acima do limite',
  },
  home: {
    pendingTooltip:
      'Depois da sua conta real ser aprovada, receberá um e-mail com os seus detalhes de início de sessão na {platfotm} para que possa começar a negociar! Entretanto, pode depositar fundos na sua conta para receber uma abertura prioritária de conta.',
    leverage: {
      tnc: '<span>Termos e Condições</span><p>Ao submeter este pedido, eu reconheço que estou ciente de que a elevada alavancagem acarreta um elevado grau de risco para o meu capital e que existe a possibilidade de sofrer uma perda maior que, e não limitada, ao capital que eu depositei.<br><br>Concordo que o emissor do produto tem o direito de rever as minhas atividades de negociação e ajustar os níveis de alavancagem na minha conta de negociação a seu critério e sem nenhum aviso ou notificação prévia.<br><br>Eu compreende que, ao escolher uma elevada alavancagem, posso negociar com margem maior, mas sofrer potencialmente perdas significativas.</p>',
      tncConfirm: 'Eu li e concordo com os termos e condições.',
      changeLeverageConfirm:
        'O seu pedido foi submetido e será processado em breve pela nossa equipa de suporte.',
      changeLeverageAutoComplete: 'O seu pedido de alteração de alavancagem foi concluído',
      changeLeverageReject: 'O seu pedido de alteração da alavancagem foi rejeitado',
      changeLeverageFail:
        'O pedido de alteração da alavancagem falhou, por favor, tente mais tarde',
      changeLeverageDefault:
        'Ocorreu um erro, por favor, entre em contacto com ${supportEmail} para finalizar a sua inscrição',
      alreadyApplied:
        'Já solicitou a alteração da alavancagem da sua conta e está atualmente em revisão pela equipa de suporte da {platform}. Por favor, aguarde até que este pedido seja finalizado antes de enviar outro pedido.',
      reduceLeverageWarn:
        'Observação: A redução de sua alavancagem pode resultar no fechamento forçado de posições abertas se sua conta tiver margem insuficiente.',
      reduceLeverageConfirm: 'Você deseja continuar?',
    },
    pw: {
      error: 'Falha ao redefinir a sua palavra-passe. Por favor, tente novamente mais tarde',
      confirm:
        'A sua palavra-passe foi atualizada.<br>Agora pode utilizar a sua nova palavra-passe para aceder à sua conta de negociação MT4/MT5.',
      email:
        '<p class="first_info">Acabamos de lhe enviar um e-mail com instruções para redefinir a sua palavra-passe.</p><p>Por favor, certifique-se de que verifica a pasta de spam do seu e-mail.</p>',
    },
    activate: {
      question: 'Deseja reativar a sua conta real?',
      confirm: 'Recebemos o seu pedido de ativação.<br>A sua conta será ativada em breve.',
    },
    accStatus: {
      active: 'Ativa',
      rejected: 'Rejeitado',
      inactive: 'Inativa',
      processing: 'Em Processamento',
      activate: 'Ativar',
      expired: 'Expirada',
    },
    appNote: {
      desc01:
        'Basta iniciar sessão na App da {platform} com o seu endereço de e-mail e palavra-passe do portal.',
      desc02: 'Clique aqui para saber mais.',
    },
  },
  paymentDetails: {
    subHeader: 'ADICIONE UM MÉTODO DE PAGAMENTO',
    payMethod: 'Forma de Pagamento',
    tip: '<p>Selecione o seu método de pagamento preferido para depósitos e levantamentos futuros, no menu abaixo.</p><br />\n   <p><b>Por favor, tenha  atenção:</b> Para garantir que os seus pedidos de depósito e levantamento possam ser processados imediatamente, os regulamentos de AML/CTF exigem que o nome apresentado no método de pagamento escolhido seja\n      <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Obrigado por submeter o método de financiamento para a sua conta de negociação. Estamos a processar o seu pedido e entraremos em contacto em breve.',
      unionPayCardSuccessMessage:
        'Os detalhes do Union Pay Card foram submetidos com sucesso, os seus novos dados bancários estarão disponíveis assim que forem aprovados',
    },
    history: { header: 'MÉTODOS DE PAGAMENTO' },
    cc: {
      field: {
        ccNum: 'Número do Cartão de Crédito',
        first6: 'Primeiros Seis',
        last4: 'Últimos Quatro',
        withrawlable: 'O levantamento máximo disponível para este cartão é {amount}.',
      },
      formValidation: { upload: 'A Foto do Cartão de Crédito é obrigatória' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'A buscar o seu estado de depósito... {countDown} segundos restantes.',
      successMsg: 'Obrigado pelo seu depósito, os seus fundos vão aparecer na sua conta em breve.',
      rejectMsg: 'Rejeitado',
      failureMsg: 'O seu depósito não foi bem sucedido. Por favor, tente novamente.',
      message:
        'Obrigado por depositar na sua conta. O seu depósito será aprovado manualmente pela equipa de contas da {platform} em breve. Os fundos serão depositados na sua conta assim que forem aprovados',
      defaultException:
        'Para confirmar o seu depósito com a {platform}, por favor entre em contacto com o suporte {email}',
    },
    failed: {
      countDownMsg: 'A buscar o seu estado de depósito... {countDown} segundos restantes.',
      rejectMsg: 'Rejeitado',
    },
  },
  deposit: {
    default: {
      deposit: 'Depositar',
      sendReceipt: 'Enviar recibo',
      successMsg:
        'Os fundos vão aparecer na sua conta de negociação assim que recebermos o depósito e verificarmos o recibo da transação.',
      failed: 'Falha no depósito, por favor tente novamente mais tarde.',
      fee: 'TAXA',
      instant: 'INSTANTÂNEO',
      hour: 'HORA',
      businessDays: 'DIAS ÚTEIS',
      bankDetails: 'Detalhes Bancários',
      sendReceiptAlert:
        'Por favor, aguarde de 1 a 3 dias úteis para que os fundos sejam creditados na sua conta.',
      disclaimer:
        '<li>{platform} strictly does not accept third-party payments. All funds deposited into your trading account must be under the same name as your {platform} trading account.</li>\n      <li>Clients acknowledge that they may be required to provide information on the source(s) of the deposit(s), the Clients’ wealth in general and any other information {platform} deems necessary to identify the source of funds when processing a deposit request and agree to provide all information required by {platform}. Failure to provide the required information may result in significant delaysin the process of your requests. {platform} will not be responsible for any delays or losses suffered as results of that delay.</li>\n      <li>Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>\n      <li>{platform} will not be responsible for any delays/errors caused by payments systems.</li>\n      <li>{platform} will not be responsible any fees that the sending financial institution or third-party payment providers may charge you for sending payments.</li>',
      channels: {
        creditOrDebitPrimary: 'Cartão de Crédito/Débito',
        i12BankTransfer: 'Transferência Bancária Internacional',
        unionPay: 'Depósito com Union Pay',
        unionPayCN: 'Transferência China Union Pay',
        mobilePay: 'Mobile Pay',
        fasa: 'Depósito com Fasapay',
        neteller: 'Depósito com Neteller',
        skrill: 'Skrill/Moneybookers',
        vietInstant: 'Transferência Bancária Instantânea do Vietnam',
        mysInstant: 'Transferência Bancária Instantânea do Malaysia',
        indoInstant: 'Transferência Bancária Instantânea Indonésia',
        thaiInstant: 'Transferência Bancária Instantânea Tailandesa',
        laosInstant: 'Transferência Bancária Instantânea de Laos',
        indiaInstant: ' Transferência Bancária Instantânea India',
        crypto: 'Depósito com Criptomoeda',
        dasshpeWallet: 'Carteiras da Índia',
        hkBankTransfer: 'Transferência Bancária Local de Hong Kong',
        pagsmile: 'Transferência Bancária Local Brasil',
        BanxaCC: 'Transferência\n  Bancária Instantânea Holandesa',
      },
      rate: {
        rate: 'Taxas de Câmbio({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Falha ao buscar a taxa de {oldCurrency} para {newCurrency}.',
          channelError: 'Falha ao buscar o canal de pagamento.',
        },
      },
      availableCurrency: 'Moeda disponível:',
    },
    method: {
      desc: 'Como depositar na sua conta com o {method}',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2:
        'Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online da {country}',
      inst3: 'ga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4:
        'Será então redirecionado para outro formulário para inserir os detalhes do seu cartão',
      inst5: 'Depois de inserir os detalhes do seu cartão, pressione "Submeter"',
      inst6: 'Será redirecionado de volta ao portal do cliente e a sua transação será finalizada',
      inst7:
        'Por favor, tenha em atenção: Se escolher o processo de depósito manual, o seu financiamento pode causar atrasos e levar muito tempo a creditar na sua conta de negociação',
      form: { header: 'Formulário de {method}' },
    },
    uk: { header: 'Transferência Bancária Instantânea da Reino Unido', country: 'Reino Unido' },
    laos: {
      header: 'Transferência Bancária Instantânea de Laos',
      country: 'Laos',
      inst: '2.   Digitalize o código QR com a sua app OnePay',
    },
    ida: { header: 'Transferência Bancária Instantânea da India', country: 'India' },
    malaysia: { header: 'Transferência Bancária Instantânea de Malaysia' },
    indonesia: { header: 'Transferência Bancária Instantânea Indonésia' },
    brazil: { header: 'Transferência Bancária Local Brasil', country: 'Brasil' },
    php: { header: 'Transferência Bancária Instantânea da Philippine', country: 'Philippine' },
    mexico: { country: 'México' },
    columbia: { country: 'Colômbia' },
    chile: { country: 'Chile' },
    creditOrDebit: {
      header: 'Cartão de Crédito',
      cardPay: {
        inst1:
          'Os depósitos com cartão de crédito utilizando EUR, USD, GBP estão limitados a um máximo de 25.000 por dia.',
      },
      solidPay: {
        inst1:
          'Os depósitos com cartão de crédito utilizando EUR, USD, GBP, AUD estão limitados a um máximo de 25.000 por dia.',
      },
    },
    cc: {
      header: 'DEPÓSITO DE CARTÃO DE CRÉDITO OU DÉBITO',
      remind:
        'Caso tenha alguma dificuldade em depositar na sua conta utilizando o método Primário, por favor utilize o método Crédito/Débito Secundário',
      field: {
        fundNow: 'Deposite Agora com Cartão de Crédito / Débito',
        newCard: 'Novo Cartão',
        cardNum: 'Número do Cartão de Crédito',
        nameOnCard: 'Nome no cartão',
        expDate: 'Data de Validade',
        cvv: 'Código de Segurança CVV',
        bankCode: 'Banco Emissor do Cartão',
        example: 'Exemplo de Cartão de Crédito/Débito',
      },
      cvvTip: 'Os 3 dígitos exibidos no verso do seu cartão',
      threeDomainSecQ: 'O seu cartão suporta 3D-Secure?',
      threeDomainSec:
        'O que é autenticação 3D Secure? <br /><strong>A autenticação 3D Secure é um método de prevenção de fraudes reconhecido pela Visa e Mastercard.<br /><br />Se o seu cartão suportar o 3D Secure, os seus fundos serão processados imediatamente.</strong>',
      cardUpload:
        'O cartão que está a utilizar para depositar fundos não foi vinculado à sua conta de negociação. Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
      result: {
        success:
          'Obrigado. Neste momento, estamos a verificar os detalhes do seu cartão e, uma vez verificados, os fundos deverão aparecer na sua conta dentro de 24 horas.',
        depositSuccess:
          'Obrigado pelo seu depósito, os seus fundos vão aparecer na sua conta em breve',
        error:
          'De modo a proteger a segurança da sua conta, já atingiu o máximo de tentativas de transações de depósito e suspendemos temporariamente os seus depósitos. Por favor, entre em contacto com {email}',
      },
    },
    fasa: {
      header: 'DEPÓSITO COM FASAPAY',
      desc: 'Como depositar na sua conta com o FasaPay',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Inicie a sessão na sua conta FasaPay',
      inst3: 'Siga as instruções na conta FasaPay para concluir o seu pagamento',
      inst4:
        'Fasapay deposits are limited to $25,000 USD per transaction and a maximum of $75,000 USD per day',
    },
    miji: {
      header: 'Transferência Bancária Instantânea Tailandesa',
      description:
        'Como depositar na sua conta com a Transferência Bancária Instantânea da Tailândia',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Você será redirecionado para a página de pagamento',
      inst3: 'Inicie a sessão no seu app bancário móvel e leia o código QR na página de pagamento',
      inst4:
        'Por favor, tenha em atenção: <b>O sistema irá adicionar alguns centavos ao valor solicitado.</b>',
    },
    intSwift: {
      header: 'TRANSFERÊNCIA BANCÁRIA: SWIFT INTERNACIONAL',
      desc: 'Os fundos podem ser transferidos para a {platform} através do serviço bancário online da sua instituição financeira ou, em alternativa, entrando em contacto com a instituição financeira e solicitando a transferência para a {platform}.',
      desc2:
        'Depois de fazer a transferência, por favor preencha o formulário abaixo e anexe uma cópia do recibo da transferência bancária que mostre claramente o seu nome completo, número da conta e valor do depósito para que possamos processar a sua transação.',
      options: 'Por favor, selecione a conta de negociação que deseja financiar',
      refInfo:
        '*Por favor, inclua o número da sua conta de negociação da {platform} no campo de referência da transferência bancária.',
      bussDayInfo:
        'Por favor, aguarde de 2 a 5 dias úteis para que os fundos sejam creditados na sua conta.',
    },
    mobile: {
      header: 'Mobile Pay',
      desc: 'Como depositar na sua conta com o Mobile Pay',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Leia o código QR para abrir a sua aplicação bancária móvel',
      inst3: 'Siga as instruções no seu dispositivo móvel para concluir o seu depósito',
      form: { payMethod: 'Método de pagamento:' },
      formValidation: { cnyAmt: 'O Valor CNY não pode exceder $ {limit}' },
    },
    unionPay: {
      header: 'DEPÓSITO UNION PAY',
      desc: 'Como depositar na sua conta com o UnionPay',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2:
        'Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online',
      inst3: 'Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
    },
    unionPayCN: {
      header: 'Transferência China Union Pay',
      desc: 'Como depositar na sua conta com a Transferência China Union Pay',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Será exibida uma página de detalhes do Pagamento',
      inst3: 'Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4:
        'Por favor, tenha em atenção: pode ser-lhe solicitado o número de adesão ao fazer um depósito; por favor, insira o número da sua conta de negociação como número de adesão',
      form: {
        holderName: 'Nome do Titular do Cartão UnionPay:',
        holderNameInfo:
          'Por favor, certifique-se de que o nome inserido acima seja igual ao nome do titular do seu cartão bancário',
      },
    },
    net: {
      header: 'DEPÓSITO COM NETELLER',
      desc: 'Como depositar na sua conta com o Neteller',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Inicie a sessão na sua conta Neteller',
      inst3: 'Siga as instruções na conta Neteller para concluir o seu pagamento',
    },
    skrill: {
      header: 'DEPÓSITO SKRILL/MONEYBOOKERS',
      desc: 'Como depositar na sua conta com o Skrill',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Inicie a sessão na sua conta Skrill',
      inst3: 'Siga as instruções na conta Skrill para concluir o seu pagamento',
      form: { email: 'E-mail do Skrill/Moneybookers' },
    },
    thaiPaytoday: {
      header: 'Transferência Bancária Instantânea Tailandesa',
      desc: 'Como depositar na sua conta com a Transferência Bancária Instantânea da Tailândia',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Siga as instruções para concluir o seu pagamento',
      scan: 'Digitalize para fazer um depósito',
      exp: 'Expira em {expireTime}',
      reminder:
        'Os fundos devem aparecer na sua conta dentro de 1 hora. <br/><br/>\n      Pode visualizar o estado atual do seu depósito no seu "histórico de transações". <br/>\n      Se houver algum problema com o seu depósito, por favor entre em contacto com o seu gestor de conta',
    },
    vietnam: { header: 'Transferência Bancária Instantânea do Vietnam' },
    crypto: {
      header: 'DEPÓSITO EM CRIPTOMOEDA',
      proceedQuestion: 'Você deseja prosseguir com seu depósito <br /><b>{método}</b>?',
      note: 'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
      bitcoin: {
        button: 'Bitcoin',
        desc: 'Como depositar na sua conta com BITCOIN',
        inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
        inst2:
          'Você será redirecionado para uma nova janela exibindo um endereço de carteira bitcoin',
        inst3:
          'Copie o endereço da carteira de Bitcoin e depois inicie a sessão na sua carteira de Bitcoin pessoal e transfira o valor desejado para o endereço Bitcoin.',
        inst4:
          'Por favor, tenha em atenção: os depósitos de Bitcoin são processados pelo B2BinPay à taxa de câmbio fornecida no momento da transação.',
      },
      usdt: {
        button: 'TETHER (OMNI)',
        desc: 'Como depositar na sua conta com o TETHER(OMNI)',
        inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
        inst2:
          'Você será redirecionado para uma nova janela exibindo o endereço de carteira TETHER(OMNI)',
        inst3:
          'Copie o endereço da carteira do Tether(OMNI) e inicie a sessão na sua carteira pessoal do Tether e transfira o valor desejado para o endereço do Tether(OMNI).',
        inst4:
          'Por favor, tenha em atenção: os depósitos de Tether(OMNI) são processados pelo B2BinPay à taxa de câmbio será fornecida no momento da transação.',
      },
      erc: {
        desc: 'HComo depositar na sua conta com o {erc}',
        inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
        inst2: 'Você será redirecionado para uma nova janela exibindo o endereço de carteira {erc}',
        inst3:
          'Copie o endereço da carteira do {erc} e inicie a sessão na sua carteira pessoal do Tether e transfira o valor desejado para o endereço do {erc}.',
        inst4:
          'Por favor, tenha em atenção: os depósitos de {erc}são processados pelo B2BinPay à taxa de câmbio será fornecida no momento da transação.',
      },
    },
    canada: {
      desc: 'Como depositar na sua conta com o  Interac E-Transfer',
      inst1: 'Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: 'Select bank in bank selection page and login to your online bank',
      inst3: 'Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4: 'Podemos aceitar o pagamento em CAD. Máximo de $9.999 CAD por depósito',
    },
    dasshpe: {
      headerWallet: 'Carteiras da Índia',
      inst1: 'Aprove a transação a partir da sua aplicação UPI',
      inst2: 'Siga as instruções e faça o pagamento através da sua app de carteira',
    },
    hongkong: {
      header: 'Transferência Bancária Local de Hong Kong',
      inst1: 'Siga as instruções e faça o pagamento',
      inst2: 'A sua transação será finalizada',
      inst3: 'Por favor, siga as instruções abaixo para efetuar o pagamento:',
      inst4: '1.   Por favor, inicie a sessão na sua conta bancária. ',
      inst5: '2.   Transfira os fundos para:',
      inst6:
        '3.   Após completar o pagamento, por favor, clique no botão CONCLUÍDO para prosseguir.',
      inst8: 'Aviso: ',
      inst9:
        'Este número de conta gerado é válido apenas para um depósito único e não deve ser utilizado para transações futuras, a fim de evitar atrasos na chegada de fundos.',
      inst10:
        'O montante de pagamento transferido deve corresponder ao montante submetido no pedido de depósito.',
      inst11:
        'Por favor, certifique-se de que efetua a sua transferência bancária dentro do prazo de 1 hora após ter submetido o pedido de depósito para evitar que o depósito falhe.',
      dollarsmart: {
        bankName: 'DBS BANK (HONG KONG) LIMITED 016',
        accountName: 'DOLLARSMART GLOBAL PTE. LTD.',
      },
    },
    eu: {
      header: 'Transferência Bancária Local da UE',
      inst1:
        'Selecione o banco na página de seleção do banco e inicie a sessão no seu banco online',
    },
    perfectMoney: {
      inst1: 'Inicie a sessão na sua conta Perfect Money',
      inst2: 'Siga as instruções na conta Perfect Money para concluir o seu pagamento',
    },
    banxa: { header: 'Transferência\n  Bancária Instantânea Holandesa' },
  },
  restrictDeposit: {
    header: 'NOTIFICAÇÃO IMPORTANTE',
    info: 'Infelizmente, já não estamos a aceitar depósitos de clientes residentes no Canadá.',
  },
  withdraw: {
    default: {
      alert: {
        title: 'Antes de submeter o seu pedido de levantamento, por favor note o seguinte:',
        desc1:
          'Devido às leis AML, o método que você usa para retirar deve ser o mesmo que o método usado para seu depósito. Se o valor do saque for inferior a {units} unidades da moeda base de sua conta, será cobrada uma taxa de {charge} unidades pelo banco para manuseio.',
        desc2:
          'Se possuir algum bónus de crédito na sua conta de negociação, poderemos remover a totalidade/parte dos seus bónus de crédito com base na oferta em que tiver participado, sem aviso prévio. Por favor certifique-se de ler e compreender os termos e condições antes de fazer o pedido de levantamento.',
      },
      how: 'Como financiou a sua conta de negociação？',
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
      chooseAnother:
        '<p>Valor máximo de saque para este cartão: 0</p> \n      <p>Por favor escolha outro cartão ou outra forma de pagamento</p>',
      notEnoughFunds: 'O valor inserido excede o limite para este cartão',
      success: 'O seu pedido de levantamento foi bem-sucedido e será processado brevemente',
      ccFail:
        'De modo a proteger a segurança da sua conta, já atingiu o máximo de tentativas de transações de depósito e suspendemos temporariamente os seus levantamentos. Por favor, entre em contacto com {email}.',
      checkPosFailed: 'Falha na Verificação da Posição',
      fetchTradeAccFailed: 'Falha na busca da Conta de Negociação',
      failed: 'O levantamento falhou. Por favor, tente novamente mais tarde',
      noCardWarn:
        'Você não possui nenhum cartão disponível para fazer o levantamento, por favor, em primeiro lugar registe o seu cartão em Detalhes de Levantamento.',
      equityStop:
        'Infelizmente, o seu levantamento não pode ser processado, porque vai resultar na queda da margem disponível da sua conta abaixo do nível permitido de 100%',
      equityMargin:
        'Infelizmente, atualmente não podemos processar seu saque porque sua conta está totalmente coberta.',
      equityContinue:
        'Detectamos que atualmente você tem posições em aberto; poderá ocorrer uma interrupção após a submissão do pedido de levantamento, por favor confirme.',
      YourPaymentGreaterThanAvailableBalance:
        'O valor da sua transferência é superior ao saldo disponível',
      blackList:
        'Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações',
      NetellerChargeAlert: 'O Neteller cobra uma taxa de transação de 2% para cada retirada.',
      FasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
      SkrillChargeAlert: 'Skrill cobra taxa de transação de 1% para cada retirada.',
      agreePolicy: 'Li e concordo com a{link}',
      withdrawPolicy: 'política de levantamento',
      addWithdrawalMethod: 'Adicione um método de levantamento',
      addSecondaryMethod: 'Adicione um método secundário',
      remainingWithdraw:
        'O montante restante do levantamento será dividido noutro cartão de crédito ou método de levantamento.',
      withdrawNote:
        'Por favor, tenha em consideração que processaremos o seu levantamento principalmente através do método de cartão de crédito. Se o valor do seu cartão de crédito estiver esgotado, pedir-lhe-emos que escolha outro método a fim de finalizar o seu pedido de levantamento.',
      submitYourWithdrawal: 'Submeter o seu levantamento',
      accountDetail: 'Detalhes da Conta',
      tradingPlatform: 'Plataforma de Negociação',
      accountSelect: 'Selecionar Conta',
      withdrawalAmount: 'Montante a levantar',
    },
    fasa: {
      field: { accName: 'Nome da conta do FasaPay', accNum: 'Número da conta do FasaPay' },
      formValidation: {
        accNameReq: 'O nome da conta do FasaPay é obrigatório',
        accNumReq: 'O número da conta do FasaPay é obrigatório',
      },
    },
    neteller: {
      field: { email: 'E-mail do Neteller' },
      formValidation: { emailReq: 'E-mail do Neteller é obrigatório' },
    },
    bitcoin: {
      field: { address: 'Endereço da Carteira Bitcoin' },
      formValidation: {
        addressReq: 'O endereço da Carteira Bitcoin é obrigatório',
        addressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      },
    },
    usdt: {
      field: { address: 'Endereço da carteira USDT' },
      formValidation: { addressReq: 'O endereço da Carteira USDT é obrigatório' },
    },
    paypal: {
      field: { email: 'E-mail do Paypal' },
      formValidation: { emailReq: 'O e-mail do Paypal é obrigatório' },
    },
    skrill: {
      field: { email: 'E-mail do Skrill' },
      formValidation: { emailReq: 'O e-mail do Skrill é obrigatório' },
    },
    perfectMoney: {
      field: { email: 'E-mail do Perfect Money' },
      formValidation: { emailReq: 'O e-mail do Perfect Money é obrigatório' },
    },
    transfer: {
      info: 'A {platform} irá reembolsar a taxa bancária cobrada por <u>UM</u> levantamento por mês. Os levantamentos adicionais dentro do mês incorrem numa taxa bancária de 20 unidades da sua moeda de levantamento.',
      label: {
        upload:
          'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
      },
      formValidation: { upload: 'Por favor, faça o envio de um extrato bancário' },
    },
    otherMethod: {
      withdrawalMethod: 'Método de Levantamento',
      manageWithdrawalDetails: 'Gerir detalhes de levantamento',
      verificationCode:
        'O código de verificação foi enviado para {email}. Por favor, entre em contacto com {supportEmail} se precisar de mais assistência',
    },
  },
  transfer: {
    creditWarn:
      'Não podemos processar a sua transferência de fundos da conta selecionada uma vez que a sua conta contém crédito promocional.',
    blackListWarn:
      'A transferência de fundos da sua conta MAM pode resultar na liquidação de posições geridas. Por favor, entre em contacto com {mailTo} se desejar transferir fundos desta conta.',
  },
  changePw: {
    header: 'ALTERAR PALAVRA-PASSE',
    field: {
      currPw: 'Palavra-passe Atual',
      newPw: 'Nova Palavra-passe',
      confirmPw: 'Confirmar a Nova Palavra-passe',
    },
    succ: 'A sua palavra-passe foi atualizada. Já pode utilizar a sua nova palavra-passe para aceder ao Portal do Cliente.',
  },
  downloads: {
    mt4: 'MetaTrader 4',
    vtapp: '{platform} APP',
    mt4Desc: 'Descarregue a MetaTrader 4 Para PC,  smartphones e tablets',
    mt5Desc: 'Descarregue a MetaTrader 5 Para PC,  smartphones e tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'MetaTrader 5',
  },
  forgetPwReq: {
    header: 'INSIRA O SEU E-MAIL DE INÍCIO DE SESSÃO',
    tip: 'Digite o endereço de e-mail de sua conta para redefinir sua senha.',
    formValidation: { emailReq: 'O e-mail de início de sessão é obrigatório' },
    succ: 'Acabamos de lhe enviar um e-mail com as instruções para redefinir a sua palavra-passe.<br>Por favor, certifique-se de verificar a pasta de spam do seu e-mail.',
  },
  loginClient: {
    header: 'PORTAL DO CLIENTE',
    failedMasseage: 'Por favor, tente novamente.',
    riskWarndesc:
      'A negociação de Forex e CFDs envolve um risco significativo e pode resultar na perda do seu capital investido.\n Não deve investir mais do que lhe é possível perder e garantir que compreende completamente os\n riscos envolvidos. A negociação de produtos alavancados pode não ser adequada para todos os investidores. Antes de negociar, por favor\n tome em consideração o seu nível de experiência, objetivos de investimento e procure aconselhamento financeiro\n independente, se necessário. Por favor, leia a nossa documentação legal e garanta que compreende totalmente os riscos\n antes de tomar quaisquer decisões de negociação.',
    generalWarn: 'Aviso de Aconselhamento Geral:',
    generalWarndesc:
      'As informações contidas neste site são apenas de natureza geral e os conselhos foram preparados sem tomar em conta os seus objetivos, situação financeira ou necessidades. Em concordância, antes de agir com base nos conselhos, deve considerar a adequação dos conselhos, tendo em conta os seus objetivos,  situação financeira e necessidades e depois de considerar a documentação legal.',
    regionRes: 'Restrições Regionais:',
    regionResdesc:
      'Não oferecemos os nossos serviços a residentes de determinadas jurisdições como Coreia do Norte, Japão, Estados Unidos e algumas outras regiões. Para mais informação, por favor, consulte a nossa página de FAQs.',
    error: {
      tip: 'Esqueceu a sua palavra-passe? Clique abaixo para a redefinir.',
      existingClientPortalAccess:
        'Verificamos que já tem acesso ao Portal do Cliente. Inicie a sessão utilizando o seu e-mail para abrir uma nova conta!',
    },
    FundingMethods: 'MÉTODOS DE FINANCIAMENTO',
    CustodianBank: 'CUSTODIAN BANK',
    ContactUs: 'ENTRE EM CONTACTO CONNOSCO',
    baseOthers:
      '<li><a href="https://{platformUrl}/trading/">Comércio</a></li>\n    <li><a href="https://{platformUrl}/tools/">Ferramentas</a></li>\n    <li><a href="https://{platformUrl}/promotions/">Promoções</a></li>\n    <li><a href="https://{platformUrl}/company/">empresa</a></li>',
    formValidation: { emailReq: 'O endereço de E-mail é obrigatório' },
  },
  myProfile: { header: 'Informação do Cliente' },
  openAdditAcc: {
    chooseTradePlat: 'ESCOLHA UMA PLATAFORMA DE NEGOCIAÇÃO',
    chooseAccType: 'ESCOLHA UM TIPO DE CONTA',
    chooseCurrency: 'ESCOLHA UMA MOEDA DE CONTA',
    chooseLeverage: 'Escolha Alavancagem',
    chooseBalance: 'SALDO DA CONTA',
    notes: 'NOTAS ADICIONAIS',
    currError: 'Selecione uma moeda de base para sua conta',
    platErr: 'Deve selecionar uma plataforma de negociação',
    accTypeErr: 'Deve selecionar um tipo de conta',
    leverageErr: 'Você deve selecionar uma alavancagem',
    balanceErr: 'Você deve selecionar um saldo',
    succ: '<p>A sua conta real adicional está a ser configurada e brevemente receberá um e-mail com os seus detalhes de acesso.</p><p>Pode visualizar o estado atual da sua inscrição na página inicial.</p>',
    byTicking: 'Ao marcar esta caixa:',
    pammTips:
      'PAMM Accounts are only for investors with an existing Money Manager arrangement. PAMM accounts cannot be traded by anyone except the Money Manager.',
  },
  openDemoAdditAcc: {
    header: 'Configuração da Conta de demonstração',
    button: 'abrir conta de demonstração',
    liveButton: 'abrir conta real',
    succ: 'A sua conta de demonstração adicional foi configurada e brevemente receberá um e-mail com seus detalhes de acesso.',
  },
  resetPassword: {
    header: 'REDEFINIR PALAVRA-PASSE',
    succ: 'A sua palavra-passe foi atualizada. Já pode utilizar a sua nova palavra-passe para aceder à sua conta de negociação da MT4/MT5.',
    succ2: 'A sua palavra-passe foi atualizada.',
    failed: 'O token expirou',
    emailNotFound: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de ficheiros suportados: {files}',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
    failedFileExtension: 'Carregue o tipo de arquivo correto',
  },
  transcationHistory: {
    deposit: 'DEPOSITAR',
    withdraw: 'LEVANTAR',
    desc: {
      incomplete:
        'Se terminar ou não concluir o processo de depósito, o estado da transação será exibido como "incompleto". Será solicitado que crie um novo depósito, uma vez que esta transação não pode ser retomada.',
      success:
        'A sua transação foi bem sucedida. Os seus fundos deverão aparecer na sua conta brevemente',
      proc: 'A sua transação está a ser processada. Por favor, entre em contacto com {mailTo}',
      failed:
        'A sua transação falhou. Por favor, entre em contacto com {mailTo} para obter mais informações',
      rejected:
        'A sua transação foi rejeitada. Por favor, entre em contacto com {mailTo} para obter mais informações',
    },
    withdrawDesc: {
      sub: 'A sua transação foi submetida. Por favor, aguarde que seja concluída.',
      suc: 'A sua transação foi bem sucedida. Os seus fundos deverão aparecer na sua conta brevemente',
      proc: 'A sua transação está a ser processada. Por favor, entre em contacto com {mailTo}',
      incomp:
        'A sua transação requer etapas adicionais para ser finalizada. Por favor, entre em contacto com {mailTo} para obter mais informações',
      cancel:
        'A sua transação foi cancelada. Por favor, entre em contacto com {mailTo} para obter mais informações',
      fail: 'A sua transação falhou. Por favor, entre em contacto com {mailTo} para obter mais informações',
      rej: 'A sua transação foi rejeitada. Por favor, entre em contacto com {mailTo} para obter mais informações',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Infelizmente, a sua transferência não pode ser processada porque resultará na queda da margem disponível na sua conta abaixo do nível de stop out de 100%',
      equityMargin:
        'Infelizmente, não podemos, de momento, processar a sua transferência porque a sua conta está totalmente coberta.',
      equityContinue:
        'Detetámos que tem atualmente posições em aberto, poderá enfrentar stop out depois de submeter o seu pedido de transferência. Por favor, confirme se deseja prosseguir.',
    },
    pleaseNote: 'Por favor, tenha em atenção:',
    suc: 'Os seus fundos foram transferidos com sucesso',
    warn1:
      'Você é responsável por manter um saldo de conta que não acione um aviso de margem. Se tem alguma posição em aberto no momento, por favor certifique-se de que mantém a margem necessária na sua conta. Os fundos transferidos para as contas mantidas na mesma moeda são processados instantaneamente.',
    warn2:
      'Se estiver a transferir fundos entre contas mantidas em moedas diferentes, envie um e-mail para {mailTo}.',
    warn3:
      'Por favor, tenha em atenção que os fundos não podem ser transferidos entre contas em modo somente leitura.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Em Processamento',
      paid: 'Pago',
      unpaid: 'Por pagar',
      rejected: 'Rejeitado',
    },
  },
  identityProof: {
    home: {
      title: 'Confirmar Identidade',
      header: 'INSCRIÇÃO DE CONTA INCOMPLETA',
      content:
        'Olá, {name}, a sua inscrição de conta está quase completa, no entanto, ainda é necessário enviar documentação adicional para finalizar a sua conta.',
    },
    shufti: {
      content:
        'Certifique-se de que os detalhes fornecidos correspondem à sua documentação de identificação e estão no mesmo idioma.',
      countryOfDocument: 'País conforme mostrado no documento',
    },
    popup: {
      header:
        'Olá, {name}, antes que a sua conta real possa ser finalizada, é necessário fazer o carregamento do seguinte documento para verificação.',
      addressProofTitle: 'Submeta <b>um</b> dos documentos abaixo',
      idProofTitle: 'Choose <b>one</b> of the options below',
    },
    reason:
      'Não foi possível verificar a sua documentação,<br> Motivo: {reason}. Por favor, tente novamente.',
  },
  menu: {
    home: 'PÁGINA INICIAL',
    accManagement: 'CONTAS',
    liveAcc: 'CONTAS REAIS',
    openAdditAcc: 'ABRIR CONTAS ADICIONAIS',
    homeDemo: 'CONTAS DE DEMONSTRAÇÃO',
    depositFund: 'DEPOSITAR FUNDOS',
    withdrawFunds: 'LEVANTAR FUNDOS',
    transferBetweenAccs: 'TRANSFERIR ENTRE CONTAS',
    payDetails: 'DETALHES DO PAGAMENTO',
    funds: 'FUNDOS',
    transactionHis: 'HISTÓRICO DE TRANSAÇÕES',
    tranHis: 'TRANSFERÊNCIAS',
    download: 'DESCARREGAMENTOS',
    profile: 'PERFIL',
    myProfile: 'O MEU PERFIL',
    cpPassword: 'ALTERAR SENHA',
    contactUs: 'CONTACTE-NOS',
    register: 'CONTINUAR A APLICAÇÃO',
    tradingTools: 'FERRAMENTAS PRO TRADER',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'CALENDÁRIO ECONÓMICO',
    FEAT_FX: 'IDEIAS EM DESTAQUE',
    FX_IDEAS: 'OPINIÕES DO ANALISTA',
    CLIENT_PORTAL: 'PORTAL DO CLIENTE',
    FEATURES: 'RECURSOS',
    referAFriend: 'Recomende um amigo',
    promotion: 'Promoções',
    depositBonus: 'Bónus de Depósito',
    UNACTIVE_noDepositBonus: 'Bónus Sem Depósito',
  },
  contact: { liveChat: 'Chat ao Vivo' },
  depositResultDialog: { msg: 'Completou com sucesso o seu depósito' },
  dialog: {
    identityProof: {
      success:
        'Obrigado. Recebemos os seus documentos com sucesso e estamos a processá-los para verificação.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESSAMENTO DA INSCRIÇÃO DE CONTA',
      id: 'ID',
      poa: 'endereço',
      both: 'ID and proof of address are',
      content:
        'O seu Comprovativo de {doc} está a ser processado de momento e será finalizado brevemente. Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.',
    },
  },
  register: {
    progressStatus: { estimatedTimeTitle: 'Tempo Estimado para Conclusão:', min: 'minutos' },
    tabs: {
      personalDetails: 'Detalhes Pessoais',
      moreAboutYou: 'Mais Sobre Si',
      accountConfig: 'Configuração da Conta',
      confirmYourId: 'Confirme a sua Identidade',
      fundYourAcc: 'Financiar a sua Conta',
    },
    btn: { next: 'PRÓXIMO', back: 'VOLTAR', fundnow: 'FINANCIAR AGORA', upload: 'Carregar' },
    authority: {
      dialog1: 'Deve ter uma conta real para aceder a esta secção do portal do cliente.',
      dialog2:
        'Abra uma conta real agora para desbloquear o acesso total ao portal do cliente, o que inclui uma variedade de ferramentas de negociação, promoções e muito mais.',
      openLiveAcc: 'abrir conta real',
    },
    demo: {
      congrad: 'Parabéns!',
      title: 'A sua conta DEMO MT {platform} de 30 dias está agora ativa',
      title2: 'Detalhes da sua Conta de DEMONSTRAÇÃO',
      li: {
        login: 'Início de sessão: ',
        password: 'Palavra-passe: ',
        srv: 'Servidor: ',
        expDate: 'Data de Validade: ',
      },
      emailSent:
        '* Os detalhes de início de sessão também foram enviados para o seu e-mail registado.',
      dl: 'Descarregue a sua Plataforma de Negociação MT{platform}',
      improveQ: 'Quer melhorar a sua negociação？',
      improveWay:
        'Basta abrir uma conta real em apenas alguns minutos para desbloquear uma variedade de ferramentas de negociação e promoções exclusivas.',
      proTrade: 'Ferramentas Pro Trader',
      depositBonus: 'Bónus de Depósito',
      smartTradeTool: 'Ferramentas Smart Trader',
      openLiveAcc: 'abrir conta real',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Detalhes Pessoais',
        userTitle: 'Título',
        firstName: 'Nome Próprio',
        lastName: 'Apelido',
        email: 'E-mail',
        dob: 'Data de Nascimento',
        idType: 'Tipo de Identificação',
        referral: 'Have you been referred by anyone?  (optional)',
        middleName: 'Nome do Meio',
        nationality: 'Nacionalidade',
        phoneNum: 'Número de Telefone',
        date: 'Dia',
        month: 'Mês',
        year: 'Ano',
        placeOfBirth: 'Naturalidade',
        idNum: 'Número de Identificação',
        passportNum: 'Número do Passaporte',
        driverLicenseNum: 'Número da Carta de Condução',
        titleDropdown: {
          mr: 'Sr.',
          mrs: 'Sra.',
          ms: 'Sra.',
          miss: 'Srta.',
          dr: 'Dr.',
          prof: 'Professor',
        },
        idTypeDropdown: {
          passport: 'Passaporte',
          idCard: 'Cartão Nacional de Identidade',
          driverLicense: 'Carta de Condução',
        },
      },
      page2: {
        pageTitle: 'ENDEREÇO DE RESIDÊNCIA PRINCIPAL',
        mainResidency: 'País de residência principal',
        streetNumber: 'Número na Rua',
        streetName: 'Nome de Rua',
        address: 'Endereço',
        provinceOrState: 'Concelho / Distrito',
        cityOrSuburb: 'Cidade / Freguesia',
        postcode: 'Código Postal',
        usQuestion:
          'É Cidadão/Residente nos EUA para fins fiscais ou possui ligações/laços nos EUA (por exemplo, conta Bancária etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'DETALHES FINANCEIROS E DA SITUAÇÃO PROFISSIONAL',
      pageTitle2: 'Conhecimento e Experiência de Negociação',
      complianceExplain:
        'As seguintes perguntas são recolhidas para conformidade com os requisitos da AML/CTF.',
    },
    accountConfig: {
      pageTitle: 'Configuração da Conta',
      chooseTradingPlatform: 'Escolha a Plataforma de Negociação',
      chooseAccType: 'Escolha o Tipo de Conta',
      chooseAccCurrency: 'Escolha a Moeda da Conta',
    },
    confirmYourId: {
      pageTitle: 'Confirme a sua Identidade',
      description:
        'Como corretora forex regulamentada, somos obrigados a verificar a sua identidade. Por favor, faça o carregamento de um dos seguintes documentos, para a verificação do seu Comprovativo de Identificação e Comprovativo de Morada. Como alternativa, pode enviar os seus documentos de verificação por e-mail para {mailTo}',
      poidTitle: 'Comprovativo de Identificação',
      porTitle: 'Comprovativo de Endereço',
      uploadDescription: 'Escolha um tipo de documento e faça o carregamento em concordância',
      pending: { upload: 'Carregamento Pendente', verification: 'Verificação Pendente' },
      poidTypes:
        '<li>Identificação com Foto</li><li>Valid Passport (including photo page and signature page)</li><li>Carta de Condução</li>',
      porTypes:
        '<li>Fatura de Serviços</li>      <li>Extrato Bancário</li>\n      <li>Certificado de Residência</li>',
    },
    finishPage: {
      title: '{individualUserName}, obrigado por se inscrever numa conta real {platformName}!',
      contentDefault:
        'Recebemos a sua inscrição e configuramos a sua conta real <b>{account}</b>. <br />\n      Em breve, receberá um e-mail em <b>{email}</b> contendo os seus detalhes de início de sessão e um link para visualizar o estado da sua conta. <br />',
      contentWithoutId3WithAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br /><br />\n      Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.<br /><br />',
      contentWithoutId3WithoutAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      'Aceda à cobertura jornalística e conteúdos de redes sociais, com notícias de mais de 35.000 ativos negociáveis.',
    FEAT_FX:
      'Veja as configurações técnicas de negociação acionáveis e acompanhe o seu progresso em tempo real.',
    ECON_CALENDAR:
      'Aproveite os dados económicos em tempo real, 24 horas por dia, de todo o mundo.',
    FX_IDEAS:
      'Consulte visões de negociação técnica motivadoras e educacionais sobre 80.000 instrumentos.',
    condition: {
      title: 'COMO ACEDER ÀS FERRAMENTAS PRO TRADER',
      needDeposit:
        '1. Certifique-se de que a sua conta é financiada com um mínimo de ${minLimit}\n      <br />2. Concorde com os termos e condições.',
      congratulations: 'Parabéns! Está pronto para desbloquear as suas Ferramentas Pro Trader!',
      needTnc: 'Eu concordo com o{link}desta promoção',
      link: 'termos e condições',
      unlock: 'Desbloquear as Ferramentas Pro Trader',
      depositNow: 'DEPOSITAR AGORA',
    },
  },
  promotion: {
    referFriends: {
      referAFriend: 'Recomende um amigo',
      confirmQ: 'Resgate o seu Bónus',
      amount: 'Montante: ',
      confirmQ2: 'O montante total convertido para a sua moeda base é {amount} {currency}',
      redeem: 'Resgatar Agora',
      agree: '*ao clicar em resgatar agora, está a concordar com todos os {link}.',
      cpy: 'Copiar Ligação',
      overBonus:
        '* o nosso assistente detetou que tem mais de $1000 de bónus. Para reclamar o bónus, por favor, contacte o nosso apoio ao cliente.',
      title: 'Recomende os seus amigos e ganhe um bónus em dinheiro!',
      subtitle:
        'Está a divertir-se ao negociar com os mercados VT? Recomende-nos aos seus amigos para ganhar um bónus em dinheiro quando eles se juntarem a nós!',
      yourRLink: 'O Seu Link De Recomendação',
      start: 'Como começar',
      step1:
        'Copie a sua ligação de recomendação, envie-a diretamente a um amigo ou partilhe-a através de e-mail ou nas redes sociais.',
      cpyLink: 'Copiar Ligação',
      step2:
        'Assim que o seu amigo abra uma conta connosco e use a ligação de recomendação, será capaz de acompanhar os detalhes na lista de recomendações abaixo.',
      step3:
        'Ganhará até $200 por cada amigo que recomendar. Ao clicar no botão "Resgatar", o bónus irá aparecer na sua conta de negociação sem condições de levantamento*.',
      viewTnc: 'Ver termos e condições completos',
      ReferralList: 'Lista de Recomendações',
      RedeemAll: 'Resgatar Tudo',
      fName: 'Nome de Recomendação',
      spDate: 'Data de Registo',
      status: 'Estado',
      redeemableAmount: 'Montante de Resgate',
      redeemStatus: 'Estado do Resgate',
      successRedeemed: 'Recebemos o seu pedido, e iremos processá-lo em breve.',
      redeemButtonRedeem: 'Resgatar',
      redeemButtonRedeemedAll: 'Resgatou todos',
      redeemButtonRedeemed: 'Resgatado',
      statusAccountOpened: 'Conta Aberta',
      statusTraded: 'Negociado',
      statusDepositFinalised: 'Depósito Finalizado',
      statusDepositLeads: 'Leads',
      statusDepositExpired: 'Expirado',
      title_v2: 'Recomende um amigo e ganhe até $220!',
      subtitle_v2:
        'Quando o seu amigo completar os seguintes passos no prazo de 30 dias, ganhará até $220! E não há limite para quantos amigos pode recomendar!*',
      tnc_v2: 'Aplicam-se os termos e condições. {link} para visualizar.',
      link: 'Clique aqui',
      registerALiveAccount: 'Registar uma conta real',
      proofOfIDApproved: 'Comprovativo de identificação aprovado',
      fundYourLiveAccount: 'Financie a sua conta real',
      tradeOneFxLot: 'Negociar um lote de fx',
      tradeFiveFxLots: 'Negociar cinco lotes de fx',
      totalEarnings: 'Ganhos totais',
      referralLink: 'Ligação de recomendação',
      share: 'Partilhar',
      redemptionIsSuccessful: 'O seu resgate foi bem-sucedido!',
      referralActivity: 'Atividade de Recomendação',
      registered: 'Registado',
      madeDeposit: 'Depósito Feito',
      tradedOneLot: 'Um Lote Negociado',
      tradedFiveLots: 'Cinco Lotes Negociados',
      daysRemaining: '{day} Dia(s) restante(s)',
      youAreNotEligibleForThisRedemption: 'Não é elegível para este resgate',
      referralsNotFound: 'Recomendações não encontradas',
      maximumReferralsReached: 'Máximo de recomendações atingido',
      noVoucherAvailable: 'Não existe voucher disponível para resgate',
      redeemYourEarnings: 'Resgate os seus ganhos',
    },
    depositBonus: {
      depositBonus: 'Deposite e reclame o seu bónus de negociação',
      title: 'Reclame o seu bónus e impulsione a sua negociação',
      subtitle1: 'Quer mais poder de negociação?',
      subtitle2:
        'O bónus de depósito da VT Markets é personalizado exclusivamente para todos os tipos das nossas contas, tudo o que tem de fazer é abrir uma conta e fazer depósitos.',
      subtitle3:
        'O seu bónus de crédito aparecerá na sua conta de negociação assim que recebermos os seus fundos.',
      fund: 'Financie a sua conta',
      tnc: '*Termos e condições aplicáveis, {link}',
      link: 'clique aqui para ver.',
      bonusHistory: 'Histórico de Bónus',
      overBonus:
        '* O nosso assistente detetou que atingiu o máximo de $10000 de bónus, os depósitos adicionais não serão creditados.',
      opt: 'Confirmação de Participação e de Não Participação',
      tick: 'Por favor, desmarque a caixa se desejar optar por <br/>não participar ou assinale a caixa se desejar participar.',
      saveChange: 'Guardar Alterações',
      creditReceived: 'Crédito Recebido',
      CreditRemoved: 'Crédito Removido',
      welcomeBonusTable: 'Bónus de Boas-vindas',
      depositBonusTable: 'Bónus de Depósito',
      depositDate: 'Data de Depósito',
      credit: 'Crédito',
      optIn: 'Participação com sucesso',
      optOut: 'Desativação com sucesso',
    },
    UNACTIVE_noDepositBonus: {
      title: 'Solicite os seus ${amount} de bónus sem depósito',
      claimNow: 'Solicitar Agora',
      needTnc: 'Concordo com o {link} desta oferta.',
      link: 'termos e condições',
      subtitle:
        'Comece a negociar ao Vivo com ${amount} GRÁTIS de BÓNUS SEM DEPÓSITO de uma das melhores corretoras de Forex do mundo de hoje. Desfrute de negociação Forex ao vivo sem qualquer depósito, bem como risco. Clique no botão abaixo para receber um capital inicial de negociação e iniciar a sua jornada de negociação ao vivo.',
      claimHistory: 'Histórico de Solicitações',
      claimDate: 'Data da solicitação',
      tradingAccount: 'Conta de Negociação',
      confirmAcc:
        'Por favor, confirme que o bónus creditado na sua conta é apresentado como indicado abaixo',
      alertDescp: '*Já não é elegível para esta oferta.',
      promptMessage:
        'É elegível para solicitar um bónus de boas-vindas, saiba mais <a href="{noDepositBonusLink}">aqui.</a>',
      claimedSucc: 'Solicitado com sucesso, iremos em breve creditar o bónus na sua conta.',
    },
  },
  social: {
    trading: {
      performance: {
        fee: {
          description:
            'A sua conta de negociação tem atualmente a VTrade ativada. Se desejar fazer um levantamento, será deduzida da sua conta uma taxa de desempenho de aproximadamente $ {performanceFee}. Por favor, tenha em consideração que o montante pode variar ligeiramente.',
        },
      },
    },
  },
  footer: {
    riskWarn:
      'Aviso de Risco: A negociação de Forex e CFDs envolve um risco significativo e pode resultar na perda do seu capital investido\n. A negociação de produtos alavancados pode não ser adequada para todos os investidores. Antes de negociar, por favor tenha em\n consideração o seu nível de experiência, objetivos de investimento e procure aconselhamento financeiro independente, se necessário.\n Por favor, leia a nossa documentação legal no nosso site e certifique-se de que compreende completamente os riscos antes de tomar quaisquer\n decisões de negociação.',
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    553: 'Não pode levantar/transferir devido ao crédito na sua conta. Por favor, entre em contacto com {email} para obter mais ajuda',
    554: 'O valor solicitado é zero ou menor que o valor mínimo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    568: 'Esta conta está impedida de fazer um depósito, por favor contacte support@alphatick.com',
    569: 'O seu nome/apelido/número de telefone próprio é obrigatório. Se precisar de assistência, por favor, contacte support@alphatick.com',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de retirar fundos.',
    584: 'Não tem compensações aplicáveis',
    587: 'Valor de saque inválido.',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    595: 'Como você está participando de nossas atividades, sua solicitação de transferência não pode ser processada no momento.',
    647: 'Código de verificação inválido',
    1001: 'A sua conta está temporariamente indisponível, por favor, contacte o seu gestor de conta',
    1103: 'A conta foi desativada',
    1208: 'Os códigos de verificação não podem ser enviados repetidamente',
    1209: 'Erro ao enviar o código de verificação',
    session_timeout:
      'O tempo limite da sessão foi excedido, está a ser redirecionado para a página de início de sessão.',
  },
};
