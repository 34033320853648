import { polyfill } from 'es6-promise'
import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import i18n from '@/lang'
import 'jquery'
import VueClipboard from 'vue-clipboard2'
import store from './store'
import SlideVerify from 'vue-monoplasty-slide-verify'
//global redirect functions
import redirect from '@/util/redirect'
import config from './config'
import '@/assets/css/global/reset.scss'
import '@/assets/css/global/element.scss'
import '@/assets/css/global/public.scss'
//global filters
import '@/util/filter'
import '@/mixins/global'
import Storage from 'vue-ls'
import InputForm from '@/components/form/Input'
import SelectForm from '@/components/form/Select'

const options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}

Vue.use(Storage, options)
Vue.use(SlideVerify)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.component('InputForm', InputForm)
Vue.component('SelectForm', SelectForm)
Vue.prototype.$redirect = redirect
Vue.prototype.$config = config

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  components: { App },
  template: '<App/>',
})
