const svg = require('@/regulator/svg.js')

const regulator = {
  svg,
}

module.exports = {
  platformLang: 'en_US',
  info: {
    fullName: 'AlphaTick',
    entityName: 'ALPHATICK LLC',
    getUrl(reg) {
      return reg ? regulator[reg].info.url : ''
    },
    getUrl2(reg) {
      return reg ? regulator[reg].info.url2 : ''
    },
    getMap(reg) {
      return regulator[reg].info.map
    },
    getAddress(reg) {
      return regulator[reg].info.address
    },
    getEmail(reg) {
      return reg ? regulator[reg].info.email : ''
    },
    getDepositEmail(reg) {
      return reg ? regulator[reg].info.depositEmail : ''
    },
    getName(reg) {
      return reg ? regulator[reg].info.name : ''
    },
    getPhone(reg) {
      return reg ? regulator[reg].info.phone : ''
    },
    getWithdrawalEmail(reg) {
      return reg ? regulator[reg].info.withdrawalEmail : ''
    },
  },
  restrictOpenAdditionAccountCountries(reg) {
    return reg ? regulator[reg].restrictOpenAdditionAccountCountries : {}
  },
  restrictDepositFundsByCountries(reg) {
    return reg ? regulator[reg].restrictDepositFundsByCountries : {}
  },
  menu: [
    {
      name: 'menu.home',
      path: '/home',
      icon: require('@/assets/images/menu/menu_icon_home.png'),
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: require('@/assets/images/menu/menu_icon_account.png'),
      children: [
        {
          name: 'menu.liveAcc',
          path: '/liveAccount',
        },
        {
          optional: true,
          name: 'menu.openAdditAcc',
          path: '/openLiveAccount',
        },
        {
          name: 'menu.homeDemo',
          path: '/homeDemo',
        },
      ],
    },
    {
      name: 'menu.funds',
      path: '/funds',
      icon: require('@/assets/images/menu/menu_icon_funds.png'),
      children: [
        {
          checkBlacklist: true,
          name: 'menu.depositFund',
          path: '/depositFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.withdrawFunds',
          path: '/withdrawFunds',
        },
        {
          name: 'menu.transferBetweenAccs',
          path: '/transferFunds',
        },
        {
          name: 'menu.transactionHis',
          path: '/transactionHistory',
        },
      ],
    },
    {
      name: 'menu.profile',
      path: '/profile',
      icon: require('@/assets/images/menu/menu_icon_profile.png'),
      children: [
        {
          name: 'menu.myProfile',
          path: '/myProfile',
        },
        {
          name: 'menu.cpPassword',
          path: '/changePassword',
        },
      ],
    },
    {
      name: 'menu.download',
      path: '/downloads',
      icon: require('@/assets/images/menu/menu_icon_downloads.png'),
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: require('@/assets/images/menu/menu_icon_contact.png'),
    },
    {
      name: 'menu.register',
      path: '/register',
      icon: require('@/assets/images/menu/menu_icon_register.png'),
    },
  ],
  accountTypeMaps: {
    0: 'ALL',
    1: 'Standard STP',
    2: 'Alpha ECN',
    3: 'Standard STP & Binary Options',
    4: 'Raw ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Swap-free Standard STP',
    7: 'Swap-free Alpha ECN',
    8: 'PRO ECN',
    9: 'VIP STP',
    10: 'PAMM',
    101: 'Standard STP', // MT5 Standard STP
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Standard STP', // Hedge STP
    14: 'Alpha ECN', // Hedge ECN
    15: 'Swap-free Standard STP', // MT5 ISLAMIC STP
    16: 'Swap-free Alpha ECN', // MT5 ISLAMIC ECN
    17: 'MT5 PRO', //MT5 PRO ECN
    18: 'VIP STP',
    28: 'PAMM'
  },
  openLiveAccount: {
    choosePlatform(reg) {
      return regulator[reg].openLiveAccount.choosePlatform
    },
    // open mt4, mt5 live account
    chooseLiveType(reg) {
      return regulator[reg].openLiveAccount.chooseLiveType
    },
    ibChooseType(reg) {
      return regulator[reg].openLiveAccount.ibChooseType
    },
    // register mt4, mt5 account
    mt4RegisterChooseType(reg) {
      return regulator[reg].openLiveAccount.mt4RegisterChooseType
    },
    // open mt4 demo account
    mt4DemoChooseType(reg) {
      return regulator[reg].openLiveAccount.mt4DemoChooseType
    },
    // open mt5 demo account
    mt5DemoChooseType(reg) {
      return regulator[reg].openLiveAccount.mt5DemoChooseType
    },
    chooseCurrency(reg, countryCode) {
      let result = regulator[reg].openLiveAccount.chooseCurrency || []

      // Exclude PLN if not Poland country
      if (countryCode !== '3716') {
        result = result.filter(currencies => currencies.currency !== 'PLN')
      }

      return result
    },
    chooseLeverage(reg) {
      return regulator[reg].openLiveAccount.chooseLeverage
    },
    chooseBalance(reg) {
      return regulator[reg].openLiveAccount.chooseBalance
    },
    getAgreeCondition(reg) {
      return regulator[reg].openLiveAccount.getAgreeCondition
    },
  },
  getMetaTraderLinks(reg) {
    return regulator[reg].getMetaTraderLinks
  },
  bank_infos(currency, reg) {
    let bank_infos = []
    bank_infos.push({
      title: 'common.field.bankName',
      info: regulator[reg].bankInfoDetails.bank_name,
    })
    bank_infos.push({
      title: 'common.field.branchAddress',
      info: regulator[reg].bankInfoDetails.branch_address,
    })
    // 非AUD
    const account_name = regulator[reg].bankInfoDetails.account_name(currency)
    bank_infos.push({
      title: account_name[currency] ? account_name[currency].title : account_name.title,
      info: account_name[currency] ? account_name[currency] : account_name,
    })
    const account_number = regulator[reg].bankInfoDetails.account_number(currency)
    bank_infos.push({
      title: account_number[currency] ? account_number[currency].title : account_number.title,
      info: account_number[currency] ? account_number[currency] : account_number,
    })
    bank_infos.push({
      title: 'common.field.swift',
      info: regulator[reg].bankInfoDetails.swift_code,
    })
    return bank_infos
  },
  country_transferOptions: [
    {
      code: 2,
      nameKey: 'common.country.international',
    },
  ],
  paymentMethods: [{ label: 'common.withdrawChannel.unionpay', component: 'UnionPayRegister' }],
  depositDefaultAmountMin: 50,
  depositJPYAmountMin: 7000,
  depositDefaultAmountMax: 99999999.99,
  depositOneAmountMin: 1,
  deposit50AmountMin: 50,
  pageLoader(regulator = 'svg', componentName) {
    return import(`@/views/regulator/${regulator}/${componentName}`)
  },
  componentLoader(regulator = 'svg', componentName, checkFileExist = false) {
    function fileInfo(exist = false, component) {
      this.exist = exist
      this.component = component
    }

    let file = new fileInfo()

    if (checkFileExist) {
      let components

      if (components) {
        components.keys().map(requireContext => {
          const fileName = requireContext.replace('./', '').replace('.vue', '')
          if (fileName === componentName) {
            file.exist = true
            file.component = import(`@/components/regulator/${regulator}/${componentName}`)
          }
        })
      }
    } else {
      file.exist = true
      file.component = import(`@/components/regulator/${regulator}/${componentName}`)
    }

    return file
  },
  getWithdrawMethods(reg) {
    return regulator[reg].getWithdrawMethods
  },
  getCancelWithdraw(reg) {
    return reg ? regulator[reg].withdrawSetup.cancelWithdraw : false
  },
}
