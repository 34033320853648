import Vue from 'vue'

const globalMixin = {
  computed: {
    regulator() {
      return this.$store.state.common.regulator
    },
    GLOBAL_DOMAIN_WEBSITE() {
      return this.$store.state.regulator.domainWebSite || this.$config.info.getUrl(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITECN() {
      return this.$store.state.regulator.domainWebSiteCn || this.$config.info.getUrl2(this.regulator)
    },
    GLOBAL_CONTACT_EMAIL() {
      return this.$config.info.getEmail(this.regulator)
    },
    GLOBAL_DEPOSIT_EMAIL() {
      return this.$store.state.common.lang === 'th'
        ? this.GLOBAL_CONTACT_EMAIL
        : this.$config.info.getDepositEmail(this.regulator)
    },
    GLOBAL_WITHDRAWAL_EMAIL() {
      return this.$config.info.getWithdrawalEmail(this.regulator)
    },
  },
  methods: {
    // showLiveChat() {
    //   // zE('webWidget', 'open')
    //   console.log("在线客服未完成");
    // },
    insertJs(src) {
      console.log(src)
      let tag = document.createElement('script')
      tag.src = src
      let firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    },
  },
}

Vue.mixin(globalMixin)

//export globalMixin for test
export default globalMixin
