import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import registerState from './register/state'
import registerMutations from './register/mutations'
import registerActions from './register/actions'

import commonState from './common/state'
import commonMutations from './common/mutations'
import commonActions from './common/actions'

import regulatorState from './regulator/state'
import regulatorMutations from './regulator/mutations'
import regulatorActions from './regulator/actions'

import promotionState from './promotion/state'
import promotionMutations from './promotion/mutations'
import promotionActions from './promotion/actions'

import paymentState from './payment/state'
import paymentMutations from './payment/mutations'
import paymentActions from './payment/actions'

Vue.use(Vuex)

const common = {
  namespaced: true,
  state: commonState,
  mutations: commonMutations,
  actions: commonActions,
}

const register = {
  namespaced: true,
  state: registerState,
  mutations: registerMutations,
  actions: registerActions,
}

const regulator = {
  namespaced: true,
  state: regulatorState,
  mutations: regulatorMutations,
  actions: regulatorActions,
}

const promotion = {
  namespaced: true,
  state: promotionState,
  mutations: promotionMutations,
  actions: promotionActions,
}

const payment = {
  namespaced: true,
  state: paymentState,
  mutations: paymentMutations,
  actions: paymentActions,
}

export default new Vuex.Store({
  modules: {
    common,
    register,
    regulator,
    promotion,
    payment,
  },
  plugins: [
    createPersistedState({
      paths: ['common', 'regulator', 'promotion'],
    }),
  ],
})
