const { countryCodeEnum } = require('@/constants/country')

module.exports = {
  info: {
    url: 'www.alphatick.com',
    url2: 'www.alphatick.com',
    email: 'support@alphatick.com',
    name: 'AlphaTick LLC',
    depositEmail: 'support@alphatick.com',
    withdrawalEmail: 'support@alphatick.com',
    phone: '+46.731298066',
  },
  withdrawSetup: {
    cancelWithdraw: true,
  },
  openLiveAccount: {
    choosePlatform: [
      {
        value: 'mt4',
        name: 'MetaTrader4',
      },
      {
        value: 'mt5',
        name: 'MetaTrader5',
      }
    ],
    chooseLiveType: [
      {
        label: 'Standard STP',
        value: 'standardSTP',
      },
      {
        label: 'Alpha ECN',
        value: 'rawECN',
      },
      {
        label: 'Swap-free Standard STP',
        value: 'swapFreeStandardSTP',
      },
      {
        label: 'Swap-free Alpha ECN',
        value: 'swapFreeRawECN',
      },
      {
        label: 'PAMM Investor',
        value: 'PAMM'
      }
    ],
    mt4RegisterChooseType: [
      {
        label: 'Standard STP',
        value: 'standardSTP'
      },
      {
        label: 'Alpha ECN',
        value: 'rawECN'
      },
      {
        label: 'Swap-free Standard STP',
        value: 'swapFreeStandardSTP'
      },
      {
        label: 'Swap-free Alpha ECN',
        value: 'swapFreeRawECN'
      },
    ],
    mt4DemoChooseType: [
      {
        label: 'Standard STP',
        value: 'standardSTP',
      },
      {
        label: 'Alpha ECN',
        value: 'rawECN',
      },
    ],
    chooseCurrency: [
      {
        img: require('@/assets/images/openAccount/choose_currency_img1.png'),
        value: '$ USD',
        currency: 'USD',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img4.png'),
        value: '$ AUD',
        currency: 'AUD',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img2.png'),
        value: '£ GBP',
        currency: 'GBP',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img3.png'),
        value: '$ CAD',
        currency: 'CAD',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img5.png'),
        value: '€ EUR',
        currency: 'EUR',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img6.png'),
        value: '¥ JPY',
        currency: 'JPY',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img7.png'),
        value: '$ NZD',
        currency: 'NZD',
      },
      {
        img: require('@/assets/images/openAccount/choose_currency_img8.png'),
        value: 'zł PLN',
        currency: 'PLN',
      },
    ],
    chooseLeverage: [1, 25, 50, 100, 200, 300, 400, 500],
    chooseBalance: [
      { value: 1000, name: '$1000' },
      { value: 2500, name: '$2,500' },
      { value: 5000, name: '$5k' },
      { value: 10000, name: '$10k' },
      { value: 25000, name: '$25k' },
      { value: 50000, name: '$50k' },
      { value: 100000, name: '$100k' },
    ],
    getAgreeCondition: 'openAdditAcc.svg.disclaimer',
  },
  getMetaTraderLinks: {
    mt4: {
      Windows: 'https://download.mql5.com/cdn/web/alphatick.pty.ltd/mt4/alphatick4setup.exe',
      MacOS: 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg',
      Android: 'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4',
      iOS: 'https://download.mql5.com/cdn/mobile/mt4/ios?server=AlphaTick-Demo,AlphaTick-Live ',
    },
    mt5: {
      Windows: 'https://download.mql5.com/cdn/web/alphatick.pty.ltd/mt5/alphatick5setup.exe',
      MacOS: 'https://apps.apple.com/cn/app/metatrader-5-forex-stocks/id413251709',
      Android: 'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=zh&referrer=ref_id%3d5188388410154478391%26server%3dAlphaTick-Live',
      iOS: 'https://apps.apple.com/cn/app/metatrader-5-forex-stocks/id413251709 ',
    }
  },
  bankInfoDetails: {
    bank_name: { show: true, value: 'National Australia Bank' },
    branch_address: { show: true, value: '292 Pitt St, Sydney, 2000, Australia' },
    branch_name(currency) {
      // 非AUD
      return { show: false, value: '' }
    },
    account_name(currency) {
      const title = 'common.field.beneAccName'
      return { show: true, title: title, value: `VIG CLIENT SEGREGATED ACCOUNT ${currency}` }
    },
    account_number(currency) {
      const title = 'common.field.beneAccNum'
      const value = currency == 'AUD' ? '511544202' : `VIGHO${currency}01`
      return { show: true, title: title, value: value }
    },
    beneficiary_address(currency) {
      // 非AUD
      return { show: false, value: '' }
    },
    bsb_number(currency) {
      return { show: false, value: '082-080' }
    },
    swift_code: { show: true, value: 'NATAAU3303M' },
    sort_code: { show: false, value: '' },
    IBAN: { show: false, value: '' },
  },
  getWithdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    34: 'Cryptocurrency-Bitcoin',
    36: 'Cryptocurrency-USDT',
    91: 'Wise P2P'
  },
  restrictDepositFundsByCountries: [],
  restrictOpenAdditionAccountCountries: [],
}
