import { Loading } from 'element-ui'
let loadingInstance,
  isTimeout = true,
  ajaxCount = 0
function startLoading(method) {
  if (method == 'router') {
    isTimeout = false
  }
  if (method == 'ajax') ajaxCount++
  loadingInstance = Loading.service({
    lock: true,
    fullscreen: true,
    spinner: 'client-portal-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
function endLoading() {
  if (loadingInstance)
    setTimeout(() => {
      isTimeout = true
      if (ajaxCount == 0) loadingInstance.close()
    }, 500)
}
function endAjaxLoading() {
  if (ajaxCount > 0) ajaxCount--
  if (loadingInstance && isTimeout && ajaxCount == 0) loadingInstance.close()
}
export { startLoading, endLoading, endAjaxLoading }
