import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'
import elementITLocale from 'element-ui/lib/locale/lang/it'
import elementPLLocale from 'element-ui/lib/locale/lang/pl'
import elementDeLocale from '@/lang/element-ui/de.js'

import enLocale from '@/lang/at/en'
import frLocale from '@/lang/at/fr'
import esLocale from '@/lang/at/es'
import ptLocale from '@/lang/at/pt'
import itLocale from '@/lang/at/it'
import plLocale from '@/lang/at/pl'
import deLocale from '@/lang/at/de'


Vue.use(VueI18n)

const messages = {
  en_US: {
    ...enLocale,
    ...elementEnLocale,
  },
  fr_FR: {
    ...frLocale,
    ...elementFrLocale,
  },
  es: {
    ...esLocale,
    ...elementEsLocale,
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale,
  },
  de: {
    ...deLocale,
    ...elementDeLocale,
  },
  it: {
    ...itLocale,
    ...elementITLocale,
  },
  pl: {
    ...plLocale,
    ...elementPLLocale,
  },
}

const i18n = new VueI18n({
  locale: 'en_US', // set locale
  fallbackLocale: 'en_US',
  messages, // set locale messages
})

elementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
