import defaultState from '@/store/promotion/state';

export default {
  setEligibleCampaigns(state, value) {
    state.eligibleCampaigns = value
  },
  setParticipatedCampaigns(state, value) {
    state.participatedCampaigns = value
  },
  setClaimedStaus(state, value) {
    state.claimedStaus = value
  },
  setClaimedCheckedStaus(state, value) {
    state.claimedCheckedStaus = value
  },
  reset(state) {
    Object.assign(state, defaultState)
  },
}
